import React, { useState } from 'react';

import i18n from 'i18n-js';
import clsx from 'clsx';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import globalStore from 'store/globalStore';
import { ListItemText } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

import { BasicThreeDotsMenu, SectionTab, PopupConfirmation, Text } from 'components/shared';
import { CategoryDetailsData, CategoryDishData } from 'store/categoryStore/reducer';
import styles from '../styles';
import CreateOrEditCategoryModal from './CreateOrEditCategoryModal';

type Category = { name: string; id: string; position: string };

interface CategoriesProps {
    categoryDetails: CategoryDetailsData;
    onCreate: (categoryName: string, dishes: string[], position: string) => void;
    onEdit: (categoryName: string, dishes: string[], position: string, categoryId: string) => void;
    onDisableOrEnable: (categoriesIds: string[]) => void;
    onDelete: (categoryId: string) => void;
    onOrder: (id: string) => void;
    orderBy: string;
    orderNames: string[];
    asc: boolean;
}

export default function Categories(props: CategoriesProps) {
    const dispatch = useDispatch();
    const [localCategoryDetails, setLocalCategoryDetails] = useState<Category>({
        id: '',
        name: '',
        position: '',
    });
    const [editDishes, setEditDishes] = useState<CategoryDishData[]>([]);
    const [showPopupConfirmation, setShowPopupConfirmation] = useState(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const classes = styles();

    function getListMenuItems(category: {
        name: string;
        id: string;
        position: string;
        isDisabled: boolean;
    }) {
        return [
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => editCategory(category)}
            >
                <EditIcon className={classes.threeDotsItemIcon} />
                <ListItemText primary={i18n.t('common.edit')} />
            </div>,
            category.isDisabled ? (
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => showDisableOrEnablePopup(category.id, category.isDisabled)}
                >
                    <SettingsBackupRestoreIcon className={classes.threeDotsItemIcon} />
                    <ListItemText primary={i18n.t('button.restore')} />
                </div>
            ) : (
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => showDisableOrEnablePopup(category.id, category.isDisabled)}
                >
                    <RemoveCircleOutlineIcon className={classes.threeDotsItemIcon} />
                    <ListItemText primary={i18n.t('common.disable')} />
                </div>
            ),
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => showDeletePopup(category)}
            >
                <RemoveCircleOutlineIcon className={classes.threeDotsItemIcon} />
                <ListItemText primary={'Delete'} />
            </div>,
        ];
    }

    function showDisableOrEnablePopup(id: string, isDisabled: boolean) {
        setLocalCategoryDetails({ ...localCategoryDetails, id });
        setShowPopupConfirmation(isDisabled ? 1 : 2);
    }

    function showDeletePopup(category: Category) {
        if (getCategoryDishes(category.id).length > 0) {
            dispatch(
                globalStore.actionCreators.showToaster(
                    'error',
                    i18n.t('common.wantDeleteWorkAreaWithDishesError')
                )
            );
            return;
        }
        setLocalCategoryDetails(category);
        setShowDeleteConfirmation(true);
    }

    function createCategory() {
        displayCategoryModal();
        setEditDishes([]);
        setLocalCategoryDetails({ id: '', name: '', position: '' });
        setIsEdit(false);
    }

    function editCategory(category: { name: string; id: string; position: string }) {
        setEditDishes(getCategoryDishes(category.id));
        setLocalCategoryDetails(category);
        setIsEdit(true);
        displayCategoryModal();
    }

    function displayCategoryModal() {
        setShowCategoryModal(true);
    }

    function hideCategoryModal() {
        setShowCategoryModal(false);
    }

    function getCategoryDishes(categoryId: string) {
        let { dishes } = props.categoryDetails;
        dishes = dishes.filter((dish) => dish.categoryId === categoryId);
        return dishes;
    }

    return (
        <div className={classes.categoriesContainer}>
            <CreateOrEditCategoryModal
                categoryDetails={props.categoryDetails}
                isVisible={showCategoryModal}
                onClose={hideCategoryModal}
                onCreate={props.onCreate}
                onEdit={props.onEdit}
                position={localCategoryDetails.position}
                categoryName={localCategoryDetails.name}
                categoryId={localCategoryDetails.id}
                dishes={editDishes}
                isEdit={isEdit}
                categories={props.categoryDetails.categories.reduce((result: string[], current) => {
                    if (localCategoryDetails.name !== current.name) {
                        result.push(current.name.toUpperCase());
                    }
                    return result;
                }, [])}
            />

            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow key="table-head">
                        <TableCell>
                            <TableSortLabel
                                active={props.orderBy === props.orderNames[0]}
                                direction={props.asc ? 'asc' : 'desc'}
                                onClick={() => props.onOrder(props.orderNames[0])}
                            >
                                <span className={classes.tableHead}>
                                    {i18n.t('restaurant.workAreas')}
                                </span>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={props.orderBy === props.orderNames[1]}
                                direction={props.asc ? 'asc' : 'desc'}
                                onClick={() => props.onOrder(props.orderNames[1])}
                            >
                                <span className={classes.tableHead}>
                                    {i18n.t('common.numberOfDishes')}
                                </span>
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={6} padding="none">
                            <SectionTab
                                text={i18n.t('restaurant.newWorkArea')}
                                onClick={createCategory}
                            />
                        </TableCell>
                    </TableRow>
                    {props.categoryDetails.categories.map((category) => (
                        <TableRow key={category.id}>
                            <TableCell
                                className={`${category.isDisabled && classes.disabledOpacity}`}
                            >
                                <Text text={category.name} maxLength={35} />
                            </TableCell>
                            <TableCell
                                className={`${category.isDisabled && classes.disabledOpacity}`}
                            >
                                {getCategoryDishes(category.id).length}
                            </TableCell>
                            <TableCell
                                align="right"
                                className={clsx({
                                    [classes.threeDotsMenuDisabledCell]: category.isDisabled,
                                })}
                            >
                                <BasicThreeDotsMenu items={getListMenuItems(category)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <PopupConfirmation
                open={showPopupConfirmation > 0}
                close={() => setShowPopupConfirmation(0)}
                title={
                    showPopupConfirmation === 1
                        ? i18n.t('button.restore')
                        : i18n.t('common.disable')
                }
                description={
                    showPopupConfirmation === 1
                        ? i18n.t('confirmation.sureToRestoreWorkArea')
                        : i18n.t('confirmation.sureToDisableWorkArea')
                }
                activeBtn={
                    showPopupConfirmation === 1
                        ? i18n.t('button.restore')
                        : i18n.t('common.disable')
                }
                action={() => props.onDisableOrEnable([localCategoryDetails.id])}
            />
            <PopupConfirmation
                open={showDeleteConfirmation}
                close={() => setShowDeleteConfirmation(false)}
                title={i18n.t('common.wantDeleteWorkArea')}
                description={''}
                activeBtn={i18n.t('common.delete')}
                action={() => props.onDelete(localCategoryDetails.id)}
            />
        </div>
    );
}

import agent, { prepareHeaders } from 'api/agent';
import axios from 'axios';

export const Payments = {
    GetClosingsReports: (page: number = 0, limit: number = 20) =>
        agent.requests.get('/Payment/closings', { page, limit }),
    GetPayments: (page: number = 0, limit: number = 20) =>
        agent.requests.get('/Payment', { params: { page, limit } }),
    CreateNewClosingReport: () => agent.requests.put('/Payment/closings', {}, prepareHeaders()),
    GenerateClosinfReportPDF: (id: string) =>
        axios.get(`/Payment/closings/${id}`, { responseType: 'blob' }).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'daily-closing.pdf');
            document.body.appendChild(link);
            link.dispatchEvent(
                new TouchEvent('touchstart', {
                    bubbles: false,
                    cancelable: true,
                    view: window,
                })
            );
            link.dispatchEvent(
                new TouchEvent('touchend', {
                    bubbles: false,
                    cancelable: true,
                    view: window,
                })
            );
            link.click();
            link.remove();
        }),
    GeneratePaymentReceiptPDF: (id: string) =>
        axios.get(`/Payment/${id}/receipt`, { responseType: 'blob' }).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(
                new Blob([data], { type: 'application/pdf' })
            );
            window.open(downloadUrl);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'receipt.pdf');
            document.body.appendChild(link);
            link.dispatchEvent(
                new TouchEvent('touchstart', {
                    bubbles: false,
                    cancelable: true,
                    view: window,
                })
            );
            link.dispatchEvent(
                new TouchEvent('touchend', {
                    bubbles: false,
                    cancelable: true,
                    view: window,
                })
            );
            link.click();
            link.remove();
        }),
};

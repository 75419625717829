import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';
import {
    TableCell,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    Button,
    TableBody,
    ListItemText,
} from '@material-ui/core';

import { BasicThreeDotsMenu, Text } from 'components/shared';
import { DateTimePicker } from 'components/shared';
import { ApplicationState } from 'store';
import { images } from 'assets';
import { Charts, FinanceItemDetails, SelectedDateHistory } from './components';
import { getDayAgo, getFinanceTotalAmount } from './logic';
import styles from './styles';
import './print-styles.css';
import financeStore from 'store/financeStore';
import { theme } from 'config/theme';
import globalStore from 'store/globalStore';
import { FinanceServicesData } from 'store/financeStore/reducer';

export interface DateHistoryData {
    name: string;
    startDate: Date;
    endDate: Date;
    isCustom?: boolean;
}

interface FinanceProps {
    restaurantId?: string;
    isOld?: boolean;
}

const Finance = ({ restaurantId = '', isOld = false }: FinanceProps) => {
    const dispatch = useDispatch();
    const financeDetails = useSelector((state: ApplicationState) => state.finance.financeDetails);
    const financeItem = useSelector((state: ApplicationState) => state.finance.financeItem);
    const [dateFilter, setDateFilter] = useState(1);
    const [chartView, setChartView] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDatesHistory, setSelectedDatesHistory] = useState<DateHistoryData[]>([]);
    const [hoveredSwitchIcon, setHoveredSwitchIcon] = useState(0);

    const classes = styles();

    useEffect(() => {
        getFinance(1);
        return () => {
            dispatch(financeStore.actionCreators.selectFinanceItem(null));
        };
    }, []); // eslint-disable-line

    function getFinance(dayAgo: number = 0, dateHistoryList?: DateHistoryData[]) {
        const dateFrom = new Date();
        const dateTo = new Date();
        dateTo.setDate(dateFrom.getDate() - dayAgo);

        dateHistoryList && setSelectedDatesHistory(dateHistoryList);

        dispatch(financeStore.actionCreators.getFinance(dateTo, dateFrom, restaurantId));
    }

    function getTableTabs(isItem = false) {
        const tabs = [];

        if (isItem) {
            for (let i = 0; i <= 3; i++) {
                let title,
                    align = 'center';

                switch (i) {
                    case 0:
                        title = i18n.t('restaurant.product');
                        align = 'left';
                        break;
                    case 1:
                        title = i18n.t('restaurant.productPrice');
                        break;
                    case 2:
                        title = i18n.t('restaurant.numberOfOrders');
                        break;
                    case 3:
                        title = i18n.t('common.totalAmount');
                        align = 'right';
                        break;
                }
                tabs.push(
                    // @ts-ignore
                    <TableCell className={classes.eachTabFor4} key={i} align={align}>
                        <span>{title}</span>
                    </TableCell>
                );
            }
        } else {
            for (let i = 0; i <= 5; i++) {
                let title,
                    align = 'center';

                switch (i) {
                    case 0:
                        title = i18n.t('common.categories');
                        align = 'left';
                        break;
                    case 1:
                        title = i18n.t(`form.homeDelivery`);
                        break;
                    case 2:
                        title = i18n.t(`form.takeAway`);
                        break;
                    case 3:
                        title = i18n.t(`form.dineIn`);
                        break;
                    case 4:
                        title = i18n.t(`form.cancelled`);
                        break;
                    case 5:
                        title = i18n.t('common.totalAmount');
                        align = 'right';
                        break;
                }
                tabs.push(
                    // @ts-ignore
                    <TableCell className={classes.eachTabFor5} key={i} align={align}>
                        <span style={{ marginRight: i === 5 ? 12 : 0 }}>{title}</span>
                    </TableCell>
                );
            }
        }

        return tabs;
    }

    function getDateFilters() {
        const dateFilters = [];

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentMonthDay = currentDate.getDate();

        for (let i = 1; i <= 4; i++) {
            let title,
                dateHistoryList: DateHistoryData[] = [];
            switch (i) {
                case 1:
                    title = i18n.t('common.day');
                    dateHistoryList.push({
                        name: `${currentMonthDay} ${i18n.t(
                            'common.monthNames.' + currentMonth
                        )} ${currentYear}`,
                        startDate: new Date(currentYear, currentMonth, currentMonthDay),
                        endDate: new Date(currentYear, currentMonth, currentMonthDay),
                    });
                    break;
                case 2:
                    title = i18n.t('common.week');
                    dateHistoryList.push({
                        name: `${i18n.t('common.monthNames.' + currentMonth)} ${currentYear}`,
                        startDate: new Date(currentYear, currentMonth, currentMonthDay),
                        endDate: new Date(currentYear, currentMonth, currentMonthDay + 7),
                    });
                    const d = new Date();
                    const day = d.getDay(),
                        diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
                    let startingDay = new Date(d.setDate(diff)).getDate();
                    let weekCount = Math.ceil(currentMonthDay / 7);
                    if (weekCount > 1 || weekCount >= 5) {
                        // @ts-ignore
                        // startingDay += getExtraDaysUntil(d.getDay(), 1);
                    }
                    let endDay = startingDay + 7;
                    endDay = endDay > 31 ? 31 : endDay;
                    dateHistoryList.push({
                        name: `${weekCount} ${i18n.t('common.week')}`,
                        startDate: new Date(currentYear, currentMonth, startingDay),
                        endDate: new Date(currentYear, currentMonth, endDay),
                    });
                    break;
                case 3:
                    title = i18n.t('common.month');
                    dateHistoryList.push({
                        name: `${i18n.t('common.monthNames.' + currentMonth)} ${currentYear}`,
                        startDate: new Date(currentYear, currentMonth, 1),
                        endDate: new Date(currentYear, currentMonth + 1, 1),
                    });
                    break;
                case 4:
                    title = i18n.t('common.year');
                    dateHistoryList.push({
                        name: `${currentYear}`,
                        startDate: new Date(currentYear, 0, 1),
                        endDate: new Date(currentYear + 1, 0, 1),
                    });
                    break;
            }
            dateFilters.push(
                <Button
                    style={i !== dateFilter ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        setDateFilter(i);
                        setShowDatePicker(false);
                        getFinance(getDayAgo(i), dateHistoryList);
                    }}
                    className={classes.mRight20}
                    type="button"
                    color="primary"
                    // @ts-ignore
                    variant={i === dateFilter ? 'contained' : ''}
                >
                    {title}
                </Button>
            );
        }

        dateFilters.push(
            <img
                id="date-time-picker"
                onClick={() => setShowDatePicker(!showDatePicker)}
                src={images.icons.calendar}
                alt="calendar-icon"
                className={`${classes.mRight20} ${classes.basicHover}`}
            />
        );

        return dateFilters;
    }

    function closeDatePicker() {
        setShowDatePicker(false);
    }

    function getServiceTotalAmount(category: any, service: string) {
        const foundService = category.services.find(
            (eachService: { name: string }) => eachService.name === service
        );
        if (service === 'cancelled') {
            let cancelledAmount = 0;
            category.services.forEach((eachService: FinanceServicesData) => {
                eachService.cancelledDishes.forEach(
                    (cancelledDish) => (cancelledAmount += cancelledDish.amount)
                );
            });
            return cancelledAmount.toFixed(0);
        }
        if (foundService) {
            return foundService.totalAmount.toFixed(0);
        }
        return '0';
    }

    function onSelectedDateHistoryClick(clickedHistory: {
        name: string;
        startDate: Date;
        endDate: Date;
        index: number;
    }) {
        const lastHistoryIndex = selectedDatesHistory.length - 1;
        const { index, startDate, endDate } = clickedHistory;
        if (index !== lastHistoryIndex) {
            const filteredDatesHistory = selectedDatesHistory.filter((item, i) => index + 1 > i);
            const newDateFilter = 4 - index;
            dispatch(financeStore.actionCreators.getFinance(startDate, endDate, restaurantId));
            setSelectedDatesHistory(filteredDatesHistory);
            setDateFilter(newDateFilter);
        }
    }

    function getIconSource(i: number) {
        if (hoveredSwitchIcon > 0) {
            if (i === 0 && hoveredSwitchIcon === 1) {
                return images.icons.listSelected;
            }
            if (i === 1 && hoveredSwitchIcon === 2) {
                return images.icons.graphSelected;
            }
        }

        switch (chartView) {
            case true:
                switch (i) {
                    case 0:
                        return images.icons.list;
                    case 1:
                        return images.icons.graphSelected;
                }
                break;
            default: {
                switch (i) {
                    case 0:
                        return images.icons.listSelected;
                    case 1:
                        return images.icons.graph;
                }
            }
        }
    }

    function financeTotalAmountContainer() {
        return (
            <div className={`${classes.tabPriceContainer} ${classes.tabPriceAlignRight10}`}>
                <h4>{i18n.t('common.total')}: </h4>
                <div>
                    <span className={classes.currencyText}>{i18n.t('common.currencies.chf')}</span>
                    <span className={classes.currencyAmount}>
                        {getFinanceTotalAmount(financeItem ? [financeItem] : financeDetails)}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className={classes.pBottom5} style={{ height: '100%' }}>
            {!isOld && (
                <div className={classes.topContainer}>
                    {financeItem ? (
                        <div
                            className={`${classes.flexRow} ${classes.cursorHover}`}
                            onClick={() =>
                                dispatch(financeStore.actionCreators.selectFinanceItem(null))
                            }
                        >
                            <img
                                src={images.icons.backArrow}
                                alt="back-arrow"
                                className={`${classes.smallIcon} ${classes.mLeft10}`}
                            />
                            <span className={`${classes.financeTabText} ${classes.mLeft20}`}>
                                {financeItem.name}
                            </span>
                        </div>
                    ) : (
                        <span className={classes.mLeft10}>Finance</span>
                    )}

                    {financeTotalAmountContainer()}
                </div>
            )}

            <div className={classes.timePickerContainer}>
                <DateTimePicker
                    showButtons={true}
                    isDateRange={true}
                    date={new Date()}
                    futureTimeOnly={true}
                    onSubmit={(pickedDates) => {
                        if (pickedDates && !pickedDates.includes(null)) {
                            const dateHistoryList = [];
                            const dateTo = pickedDates[0];
                            let dateToDate: any = dateTo.getDate(),
                                dateToMonth: any = dateTo.getMonth() + 1;
                            dateToDate = dateToDate < 10 ? '0' + dateToDate : dateToDate;
                            dateToMonth = dateToMonth < 10 ? '0' + dateToMonth : dateToMonth;

                            const dateFrom = pickedDates[1];
                            let dateFromDate: any = dateFrom.getDate(),
                                dateFromMonth: any = dateFrom.getMonth() + 1;
                            dateFromDate = dateFromDate < 10 ? '0' + dateFromDate : dateFromDate;
                            dateFromMonth =
                                dateFromMonth < 10 ? '0' + dateFromMonth : dateFromMonth;

                            const diffTime = Math.abs(dateTo.getTime() - dateFrom.getTime());
                            const dayDifference = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                            dateHistoryList.push({
                                name: `${dateToDate}.${dateToMonth}.${dateTo.getFullYear()} - ${dateFromDate}.${dateFromMonth}.${dateFrom.getFullYear()}`,
                                startDate: dateTo,
                                endDate: dateFrom,
                                isCustom: true,
                            });

                            if (dayDifference <= 31) {
                                setDateFilter(3);
                            } else {
                                setDateFilter(4);
                            }

                            setSelectedDatesHistory(dateHistoryList);
                            return dispatch(
                                financeStore.actionCreators.getFinance(
                                    dateTo,
                                    dateFrom,
                                    restaurantId
                                )
                            );
                        } else {
                            dispatch(
                                globalStore.actionCreators.showToaster(
                                    'warning',
                                    i18n.t('warnings.selectTwoDatesToFilter')
                                )
                            );
                        }
                    }}
                    isOpen={showDatePicker}
                    onClose={closeDatePicker}
                />
            </div>

            <div className={`${classes.flexRowSpaceBetween} ${classes.p15}`}>
                {financeItem ? (
                    <span>{/*financeItem.name*/}</span>
                ) : (
                    <SelectedDateHistory
                        dateHistory={selectedDatesHistory}
                        onClick={onSelectedDateHistoryClick}
                    />
                )}

                <div className={classes.flexRow}>
                    {getDateFilters()}
                    <div className={classes.switchGraphContainer}>
                        <img
                            onClick={() => setChartView(false)}
                            onMouseOver={() => setHoveredSwitchIcon(1)}
                            onMouseLeave={() => setHoveredSwitchIcon(0)}
                            className={classes.switchBasic}
                            src={getIconSource(0)}
                            alt="switch-gaph"
                        />
                        <img
                            onClick={() => {
                                dispatch(financeStore.actionCreators.selectFinanceItem(null));
                                setChartView(true);
                            }}
                            onMouseOver={() => setHoveredSwitchIcon(2)}
                            onMouseLeave={() => setHoveredSwitchIcon(0)}
                            className={classes.switchBasic}
                            src={getIconSource(1)}
                            alt="switch-gaph"
                        />
                    </div>
                    <BasicThreeDotsMenu
                        id="hide-in-print"
                        items={[
                            <div
                                id="hide-in-print"
                                className={classes.threeDotsMenuItemContainer}
                                onClick={() => window.print()}
                            >
                                <img
                                    src={images.icons.print}
                                    alt="print-icon"
                                    className={classes.threeDotsItemIcon}
                                />
                                <ListItemText primary={i18n.t('common.print')} />
                            </div>,
                        ]}
                    />
                </div>
            </div>

            {financeItem ? (
                <FinanceItemDetails financeItem={financeItem} />
            ) : chartView ? (
                <Charts
                    selectedDatesHistory={selectedDatesHistory}
                    dateFilter={dateFilter}
                    onDateClick={(selectedDates, newDateFilter, dateHistoryList) => {
                        const { startDate, endDate } = selectedDates;
                        setDateFilter(newDateFilter);
                        setSelectedDatesHistory(dateHistoryList);
                        return dispatch(
                            financeStore.actionCreators.getFinance(startDate, endDate, restaurantId)
                        );
                    }}
                />
            ) : (
                <TableContainer className={classes.tableContainer}>
                    <Table aria-label="simple table">
                        <TableHead className={classes.opacity03}>
                            <TableRow key="table-head">{getTableTabs(!!financeItem)}</TableRow>
                        </TableHead>

                        <TableBody>
                            {!financeItem &&
                                financeDetails?.map((financeDetail, i: number) => (
                                    <TableRow
                                        onClick={() =>
                                            dispatch(
                                                financeStore.actionCreators.selectFinanceItem({
                                                    ...financeDetail,
                                                })
                                            )
                                        }
                                        className={classes.basicHover}
                                        hover={true}
                                        key={i}
                                    >
                                        <TableCell component={'td'} align={'left'}>
                                            <Text text={financeDetail.name} maxLength={30} />
                                        </TableCell>
                                        <TableCell component={'td'} align={'center'}>
                                            {getServiceTotalAmount(financeDetail, 'HomeDelivery')}
                                        </TableCell>
                                        <TableCell component={'td'} align={'center'}>
                                            {getServiceTotalAmount(financeDetail, 'TakeAway')}
                                        </TableCell>
                                        <TableCell component={'td'} align={'center'}>
                                            {getServiceTotalAmount(financeDetail, 'DineIn')}
                                        </TableCell>
                                        <TableCell component={'td'} align={'center'}>
                                            {getServiceTotalAmount(financeDetail, 'cancelled')}
                                        </TableCell>
                                        <TableCell component={'td'} align={'right'}>
                                            {financeDetail.amount}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default Finance;

import React, { useMemo, useState } from 'react';

import { useDrag } from 'react-dnd';

import { images } from 'assets';
import { OrderItem } from 'store/ordersStore/reducer';
import { Text } from 'components/shared';
import { removeWhiteSpace } from 'helpers/helperFunctions';
import { useStyles } from '../styles';

interface SectionCardProps {
    order: OrderItem;
    passive?: boolean;
}

export default function SectionCard({ order, passive }: SectionCardProps) {
    const { amount, ingredients, comment, service, name, orderNumber, qrCodeName, size } = order;
    const classes = useStyles();
    const [isScrolling, setIsScrolling] = useState(false);

    const options = useMemo(() => {
        return { dropEffect: 'move' };
    }, []);

    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: 'a',
            item: order,
            canDrag: () => !isScrolling,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            options,
        }),
        []
    );

    const handleTouchStart = () => setIsScrolling(false);
    const handleTouchMove = () => setIsScrolling(true);

    function getServiceIcon() {
        switch (service) {
            case 'HomeDelivery':
                return images.icons.chartHomeDelivery;
            case 'TakeAway':
                return images.icons.chartTakeAway;
            case 'DineIn':
                return images.icons.chartDineIn;
        }
        return images.icons.takeAway;
    }

    return (
        <div
            ref={dragRef}
            className={`${classes.sectionCardContainer} ${
                (passive || isDragging) && classes.passiveContainer
            }`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            <div className={classes.sectionCardInnerContainer}>
                <div className={classes.flexRowSpaceBetween}>
                    <span className={classes.sectionCardTitle}>{name}</span>
                    <div /* style={{ maxWidth: '33%' }} */ className={classes.flexRow}>
                        <img
                            style={{
                                height: 22,
                            }}
                            src={getServiceIcon()}
                            alt="section-icon"
                            className={classes.mRight10}
                        />
                        <Text
                            fontSize={18}
                            text={`№ ${orderNumber}`}
                            minifyThreshold={2}
                            className={classes.sectionCardOrder}
                        />
                    </div>
                </div>
                <div className={`${classes.flexRowSpaceBetween} ${classes.mTop10}`}>
                    <span className={classes.sectionCardIngredients}>{ingredients || ''}</span>
                </div>
                <div className={`${classes.flexRowSpaceBetween} ${classes.mTop10}`}>
                    <span className={classes.qrNameText}>{removeWhiteSpace(qrCodeName)}</span>
                    <div className={`${classes.flexRowSpaceBetween}`}>
                        {size && (
                            <div style={{ bottom: -6 }}>
                                <div className={classes.dishSizeCook}>{size}</div>
                            </div>
                        )}
                        {amount > 1 && (
                            <div className={classes.sectionCardAmountContainer}>
                                <span className={classes.sectionCardAmountContainerText}>
                                    {amount}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {comment && (
                <div className={classes.sectionCardClientNotesContainer}>
                    <span className={classes.sectionCardClientNotesTitle}>Client Notes</span>
                    <span className={classes.sectionCardClientNotesText}>{comment}</span>
                </div>
            )}
        </div>
    );
}

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    ListItemText,
    TextField as MuiTextField,
    DialogActions,
    Tooltip,
    InputAdornment,
    FilledInput as BaseInput,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import i18n from 'i18n-js';
import html2canvas from 'html2canvas';
import * as yup from 'yup';

import { default as restaurantStore } from 'store/restaurantDetailsStore';
import { ApplicationState } from 'store';
import { default as menusStore } from 'store/menusStore';
import { helperFunctions } from 'helpers';
import { trimLeftAndRight } from 'helpers/helperFunctions';
import { images } from 'assets';
import { BasicThreeDotsMenu, Input } from 'components/shared';
import { PopupConfirmation } from 'components/shared';
import styles from './styles';
import { OrderServices } from 'constants/enums';
import QrListModal from './components/QrListModal';

const QRCode = require('qrcode.react');

interface QRProps {
    restaurantId: string;
    downloadDineInQrName?: string;
    downloadTakeAwayQrName?: string;
    downloadHomeDeliveryQrName?: string;
    setDownloadQrName?: (name: string, service: OrderServices) => void;
    isAdmin?: boolean;
}

export const qrImageSettings = {
    src: images.icons.blackPenguin,
    width: 75,
    height: 75,
};

export const qrCodeSize = 300;

export default function QR({
    restaurantId,
    downloadDineInQrName,
    downloadTakeAwayQrName,
    downloadHomeDeliveryQrName,
    setDownloadQrName,
    isAdmin,
}: QRProps) {
    const dispatch = useDispatch();
    const menus = useSelector((state: ApplicationState) => state.menus.menus);
    const restaurantInformation = useSelector((state: ApplicationState) =>
        isAdmin ? state.restaurantDetails.restaurant : state.restaurantDetails.restaurantInformation
    );
    const qrCodes = useSelector((state: ApplicationState) => state.restaurantDetails.qrCodes);
    const classes = styles();

    const [service, setService] = useState<OrderServices | null>(null);
    const [serviceIndex, setServiceIndex] = useState(0);
    const [qrModalType, setQrModalType] = useState(0);
    const [showQrListModal, setShowQrListModal] = useState(false);
    const [qrModalValues, setQrModalValues] = useState({
        qrId: '',
        qrName: '',
        qrAreaName: '',
        qrValues: {
            menuId: '',
            tableId: '',
        },
        key: 0,
    });
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deletedQrIds, setDeletedQrIds] = useState<string[]>([]);
    const [renderForDownload, setRenderForDownload] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const existsServiceCodes = useRef<string[]>([]);

    function getDownloadQrCodeName() {
        let name = undefined;
        switch (service) {
            case OrderServices.HomeDelivery:
                name = downloadHomeDeliveryQrName;
                break;
            case OrderServices.DineIn:
                name = downloadDineInQrName;
                break;
            case OrderServices.TakeAway:
                name = downloadTakeAwayQrName;
                break;
        }

        return name || '';
    }

    const validationSchema = yup.object().shape({
        qrName: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'QR Code Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !existsServiceCodes.current.includes(value?.toUpperCase().trim())
            ),
    });

    useEffect(() => {
        if (menus.length === 0 && !isAdmin) {
            getMenus();
        }
        if (isAdmin) {
            getMenus();
            if (!restaurantInformation.id && restaurantId) {
                dispatch(restaurantStore.actionCreators.getRestaurantDetails(restaurantId));
            }
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        dispatch(
            restaurantStore.actionCreators.getQrCodes(
                isAdmin
                    ? {
                          restaurantId: restaurantId,
                      }
                    : {}
            )
        );
    }, [restaurantId]); // eslint-disable-line

    function getMenus() {
        dispatch(menusStore.actionCreators.getMenus(isAdmin ? restaurantId : ''));
    }

    function resetQrModalValues() {
        setQrModalValues({
            qrId: '',
            qrName: '',
            qrAreaName: '',
            qrValues: {
                menuId: '',
                tableId: '',
            },
            key: 0,
        });
    }

    function createNewQr(name: string, qrAreaName: string, url: string, qrValues: any) {
        const qrData = {
            name,
            url,
            service: service || OrderServices.DineIn,
            area: qrAreaName,
            areaId: '',
            qrValues,
        };
        setQrModalType(0);
        dispatch(
            restaurantStore.actionCreators.createQrCode(
                qrData,
                isAdmin
                    ? {
                          restaurantId,
                      }
                    : {}
            )
        );
    }

    function updateQr(id: string, name: string, qrAreaName: string, url: string, qrValues: any) {
        setQrModalType(0);
        const qrData = {
            id,
            name,
            url,
            service: service || OrderServices.DineIn,
            area: qrAreaName,
            areaId: '',
            qrValues,
        };
        dispatch(restaurantStore.actionCreators.updateQrCode(qrData));
    }

    function deleteQrs() {
        dispatch(restaurantStore.actionCreators.deleteQrCode(deletedQrIds));
        setDeletedQrIds([]);
        setQrModalType(0);
    }

    function getQrCodeActionList() {
        const actionList = [
            { name: 'Restaurant Information', key: 0, menuId: '', tableId: '' },
            { name: 'All Menu', menuId: 'all', key: 1, tableId: '' },
        ];

        if (menus.length > 0) {
            menus.forEach((menu, i) => {
                if (menu.isEnabled) {
                    actionList.push({
                        name: `${menu.name}`,
                        key: i + 2,
                        menuId: menu.id,
                        tableId: '',
                    });
                }
            });
        }

        return actionList;
    }

    function getQrCode(
        restaurantId: string,
        service: number,
        qrName: string,
        menuId?: string,
        tableId?: string | number
    ) {
        if (restaurantId) {
            return helperFunctions.generateQrCodeUrl(
                restaurantId,
                menuId,
                tableId,
                service,
                qrName
            );
        }
        return '';
    }

    function getServiceQrs(amount = 0, name: OrderServices) {
        const filtered = qrCodes?.filter((qrCode) => qrCode.service === name);
        if (amount) {
            return filtered.slice(0, amount);
        }
        return filtered;
    }

    async function downloadQRCodes(serviceName: OrderServices) {
        setRenderForDownload(true);
        setService(serviceName);
        const filtered = qrCodes.filter((qrCode) => qrCode.service === serviceName);

        await new Promise((resolve) => setTimeout(() => resolve('done'), 500));

        filtered.forEach((qrCode) => {
            const containerId = `qrContainerMulti-${qrCode.id}`;
            html2canvas(document.getElementById(containerId) as HTMLCanvasElement, {
                onclone: (document, element) => {
                    element.style.display = 'flex';
                },
            })
                .then((canvas) => {
                    const pngUrl = canvas
                        .toDataURL('image/png')
                        .replace('image/png', 'image/octet-stream');
                    let downloadLink = document.createElement('a');
                    downloadLink.href = pngUrl;
                    downloadLink.download = `${qrCode.service}-${qrCode.name}.png`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                })
                .catch((e) => alert(e));
        });

        setRenderForDownload(false);
    }

    function displayAddQrModal(serviceName: OrderServices, serviceIndex: number) {
        setService(serviceName);
        setServiceIndex(serviceIndex);
        setQrModalType(1);
        resetQrModalValues();
        existsServiceCodes.current = getServiceQrs(0, serviceName).map((code) =>
            code.name.toUpperCase()
        );
    }

    function hideAddQrModal() {
        setQrModalType(0);
    }

    function addOrEditQrModal() {
        function isSameQrName(currentQrName: string) {
            let hasSameName = 0;
            qrCodes.forEach((qrCode) => {
                if (
                    qrCode.name.replaceAll(/\s/g, '').toUpperCase() ===
                    currentQrName.replaceAll(/\s/g, '').toUpperCase()
                ) {
                    hasSameName++;
                }
            });
            return hasSameName > 0;
        }

        return (
            <Dialog
                open={qrModalType > 0}
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
            >
                <DialogTitle id="form-dialog-title">
                    <span>
                        {i18n.t(
                            `${qrModalType === 1 ? 'restaurant.addNewQr' : 'restaurant.editQr'}`
                        )}
                    </span>
                    {qrModalType === 2 && (
                        <Button
                            onClick={() => {
                                setDeletedQrIds([qrModalValues.qrId]);
                                setShowDeletePopup(true);
                            }}
                            className={classes.removeButton}
                            variant="contained"
                        >
                            {i18n.t('common.remove')}
                        </Button>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={qrModalValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={async (values) => {
                            const {
                                qrName,
                                qrAreaName,
                                qrId,
                                qrValues,
                                qrValues: { menuId, tableId },
                            } = values;
                            if (qrModalType === 1) {
                                createNewQr(
                                    trimLeftAndRight(qrName),
                                    qrAreaName,
                                    getQrCode(
                                        restaurantId,
                                        serviceIndex,
                                        trimLeftAndRight(qrName),
                                        menuId,
                                        tableId
                                    ),
                                    qrValues
                                );
                            } else {
                                updateQr(
                                    qrId,
                                    trimLeftAndRight(qrName),
                                    qrAreaName,
                                    getQrCode(
                                        restaurantId,
                                        serviceIndex,
                                        trimLeftAndRight(qrName),
                                        menuId,
                                        tableId
                                    ),
                                    qrValues
                                );
                            }
                        }}
                    >
                        {({ values, setFieldValue, submitForm, errors }) => (
                            <Form>
                                <>
                                    <div className={classes.addQrCodeContainer}>
                                        <QRCode
                                            value={getQrCode(
                                                restaurantId,
                                                serviceIndex,
                                                values.qrName
                                            )}
                                            size={105}
                                        />
                                    </div>
                                    <br />
                                    <Input
                                        value={values.qrName}
                                        error={
                                            errors.qrName ||
                                            (isSameQrName(values.qrName) && qrModalType === 1)
                                        }
                                        variant="filled"
                                        fullWidth={true}
                                        name={`qrName`}
                                        type="text"
                                        label={i18n.t('restaurant.nameQr')}
                                        placeholder={i18n.t('restaurant.nameQr')}
                                    />
                                    <br />
                                    <Autocomplete
                                        // @ts-ignore
                                        value={{
                                            name: values.qrAreaName,
                                            key: values.key,
                                        }}
                                        options={getQrCodeActionList()}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) =>
                                            option.key === value.key
                                        }
                                        onChange={(e: object, value: any | null) => {
                                            setFieldValue('key', value ? value.key : 0);
                                            setFieldValue('qrAreaName', value ? value.name : '');
                                            setFieldValue('qrValues', value);
                                        }}
                                        renderInput={(params) => (
                                            <>
                                                <MuiTextField
                                                    {...params}
                                                    name="type"
                                                    label={i18n.t('restaurant.selectThePage')}
                                                    variant="filled"
                                                    fullWidth
                                                />
                                            </>
                                        )}
                                    />
                                </>
                                <DialogActions>
                                    <Button onClick={hideAddQrModal} color="primary">
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        disabled={
                                            values.qrAreaName.length === 0 ||
                                            values.qrName.length === 0
                                        }
                                        color="primary"
                                        variant="contained"
                                        style={{ marginLeft: 20 }}
                                    >
                                        {i18n.t(
                                            `${qrModalType === 1 ? 'common.add' : 'common.save'}`
                                        )}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }

    function QRItem(index?: number) {
        const { homeDelivery, takeAway, dineIn } = restaurantInformation;
        let name: OrderServices = OrderServices.HomeDelivery,
            displayName: string = '',
            value: string | undefined = undefined,
            image,
            serviceIndex = index || 0,
            active = true,
            error: string = '';
        switch (index) {
            case 0:
                name = OrderServices.HomeDelivery;
                displayName = i18n.t('restaurant.homeDelivery');
                image = images.icons.chartHomeDelivery;
                active = homeDelivery.isActive;
                value = downloadHomeDeliveryQrName;
                break;
            case 1:
                name = OrderServices.DineIn;
                displayName = i18n.t('restaurant.dineIn');
                image = images.icons.chartDineIn;
                active = dineIn.isActive;
                value = downloadDineInQrName;
                break;
            case 2:
                name = OrderServices.TakeAway;
                displayName = i18n.t('restaurant.takeAway');
                image = images.icons.chartTakeAway;
                active = takeAway.isActive;
                value = downloadTakeAwayQrName;
                break;
        }

        if (!value?.trim()) {
            error = i18n.t('form.errors.required', {
                name: i18n.t('form.description'),
            });
        } else if (value.length > 40) {
            error = i18n.t('form.errors.maxLength', {
                name: i18n.t('form.description'),
                length: 40,
            });
        }

        if (!active) {
            return;
        }

        const qrLength = getServiceQrs(0, name).length;

        function displayAddQrModal(serviceName: OrderServices, serviceIndex: number) {
            setService(serviceName);
            setServiceIndex(serviceIndex);
            setQrModalType(1);
            resetQrModalValues();
            existsServiceCodes.current = getServiceQrs(0, serviceName).map((code) =>
                code.name.toUpperCase()
            );
        }

        function getThreeDotItems() {
            const items = [];
            items.push(
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => displayAddQrModal(name, serviceIndex)}
                >
                    <img
                        src={images.icons.plus}
                        alt="plus-icon"
                        className={classes.threeDotsItemIcon}
                    />
                    <ListItemText primary={i18n.t('restaurant.addNewQr')} />
                </div>
            );
            if (getServiceQrs(1, name).length > 0) {
                items.push(
                    <div
                        className={classes.threeDotsMenuItemContainer}
                        onClick={() => downloadQRCodes(name)}
                    >
                        <img
                            src={images.icons.fileDownloadDisabled}
                            alt="file-download-disabled"
                            className={classes.threeDotsItemIcon}
                        />
                        <ListItemText primary={i18n.t('restaurant.downloadAllQRs')} />
                    </div>
                );
            }
            return items;
        }

        return (
            <div className={classes.qrItemContainer}>
                <div
                    className={classes.flexRowSpaceBetween}
                    style={{ alignItems: 'unset', height: 158 }}
                    onClick={() => {
                        if (getServiceQrs(0, name).length === 0) {
                            return displayAddQrModal(name, serviceIndex);
                        }
                        setService(name);
                        setServiceIndex(serviceIndex);
                        setShowQrListModal(true);
                    }}
                >
                    <div
                        className={`${classes.qrTextContainer} ${classes.flexColumnSpaceBetweenNoAlign} ${classes.mVertical10}`}
                    >
                        <div className={classes.flexColumn}>
                            <span className={classes.qrItemTitle}>{displayName}</span>
                        </div>
                        <img
                            src={image}
                            className={classes.homeDeliveryIcon}
                            alt="home-delivery-icon"
                        />
                    </div>
                    {getServiceQrs(3, name).map((qrCode, i) => (
                        <div
                            key={i}
                            className={classes.qrCodeContainer}
                            style={{ marginLeft: i === 0 ? -50 : -120 }}
                        >
                            <QRCode value={''} size={105} />
                        </div>
                    ))}
                    {getServiceQrs(0, name).length === 0 && (
                        <div className={classes.qrCodeContainer}>
                            <span className={classes.addQrText}>{i18n.t('restaurant.addQr')}</span>
                        </div>
                    )}
                    <div
                        className={`${classes.mVertical10} ${classes.flexColumnSpaceBetweenNoAlign}`}
                    >
                        <BasicThreeDotsMenu items={getThreeDotItems()} />
                        <b>
                            {qrLength} {qrLength > 1 ? 'QRs' : 'QR'}
                        </b>
                    </div>
                </div>
                <FormControl
                    fullWidth
                    variant="filled"
                    error={!!error}
                    style={{
                        textAlign: 'start',
                        marginBottom: 10,
                        marginTop: 10,
                        height: 56,
                    }}
                >
                    <InputLabel htmlFor="filled-adornment-description">
                        {!!error ? error : i18n.t('form.description')}
                    </InputLabel>
                    <BaseInput
                        id="filled-adornment-description"
                        type="text"
                        onChange={(e: any) => {
                            setDownloadQrName &&
                                setDownloadQrName(e.target.value ? e.target.value : '', name);
                        }}
                        placeholder={i18n.t('restaurant.additionalTextForQr')}
                        value={value}
                    />
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={() => setShowTooltip(false)}
                        style={{ top: 15 }}
                        title={i18n.t('form.errors.qrMaxLenght')}
                    >
                        <InputAdornment
                            position="end"
                            style={{
                                position: 'absolute',
                                right: 12,
                                top: 30,
                                cursor: 'pointer',
                                zIndex: 25,
                            }}
                        >
                            <img alt="warning" src={images.icons.warning} />
                        </InputAdornment>
                    </Tooltip>
                    {/* {!!error && (
                        <FormHelperText style={{ display: 'block' }} id="component-error-text">
                            {error}
                        </FormHelperText>
                    )} */}
                </FormControl>
            </div>
        );
    }

    return (
        <div className={classes.qrContainer}>
            {renderForDownload &&
                getServiceQrs(0, service || OrderServices.DineIn).map((qrCode, i) => (
                    <div
                        key={i}
                        id={`qrContainerMulti-${qrCode.id}`}
                        className={classes.qrDownloadContainer}
                    >
                        <span className={classes.qrCodeTopText}>{getDownloadQrCodeName()}</span>
                        <QRCode
                            className={classes.qrCodeDownload}
                            id={qrCode.id}
                            value={qrCode.url}
                            imageSettings={qrImageSettings}
                            size={qrCodeSize}
                            includeMargin={true}
                            level={'Q'}
                        />
                        <span className={classes.qrCodeDownloadText}>{qrCode.name}</span>
                    </div>
                ))}
            {addOrEditQrModal()}
            <div className={`${classes.flexRowSpaceBetween} ${classes.mTop15}`}>
                {QRItem(0)}
                {QRItem(1)}
            </div>
            <div className={`${classes.flexRowSpaceBetween} ${classes.mTop15}`}>{QRItem(2)}</div>
            <QrListModal
                downloadQrName={trimLeftAndRight(getDownloadQrCodeName())}
                isVisible={showQrListModal}
                onClose={() => setShowQrListModal(false)}
                serviceName={service || OrderServices.DineIn}
                onEditClicked={(qrCode) => {
                    setQrModalValues({
                        qrId: qrCode.id ? qrCode.id : '',
                        // @ts-ignore
                        qrService: qrCode.service,
                        qrName: qrCode.name,
                        // @ts-ignore
                        qrAreaName: qrCode.area,
                        qrValues: {
                            menuId: '',
                            tableId: '',
                        },
                    });
                    setQrModalType(2);
                }}
            />
            <PopupConfirmation
                open={showDeletePopup}
                close={() => setShowDeletePopup(false)}
                title={i18n.t('restaurant.removeQr')}
                description={i18n.t('restaurant.removeQrWarning')}
                activeBtn={i18n.t('common.remove')}
                action={deleteQrs}
            />
        </div>
    );
}

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Form, Formik, useFormikContext, FieldArray, getIn } from 'formik';
import * as yup from 'yup';
import i18n from 'i18n-js';
import * as FormikFields from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import {
    TextField,
    InputAdornment,
    IconButton,
    FilledInput as NativeInput,
    InputLabel,
    FormControl,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import clsx from 'clsx';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

import { AddIngredientsModal, Input } from 'components/shared';
import { default as restaurantStore } from 'store/restaurantDetailsStore';
import { DishCreateModel, IngredientModel } from 'api/models';
import { images } from 'assets';
import categoryStore from 'store/categoryStore';
import { DishData, SectionData } from 'store/menuDetailsStore/reducer';
import { trimLeftAndRight, truncateString } from 'helpers/helperFunctions';
import ImageContainer, {
    ImageContainerRefProps,
} from 'components/shared/components/ImageContainer';
import AllergensSelect from 'components/shared/components/AllergensSelect';
import ServiceTaxSelect from './ServiceTaxSelect';
import {
    OrderServices,
    TaxType,
    Positions,
    SpicinessLevels,
    Diets,
    DietaryLaws,
} from 'constants/enums';
import { useStyles } from '../styles';
import { useAppSelector } from 'index';
import { regexHelper } from 'helpers/regexHelper';
import { getSpicinessLevels, getDiets, getDietaryLaws } from 'constants/maps';
import DishDietarySelect from 'components/shared/components/DishDietarySelect';
import TextEditor from 'components/shared/components/TextEditor';
import LabelSelect from 'components/shared/components/LabelSelect';
import SetFieldText from 'components/shared/components/SetFieldText';

export interface DishManagementDialogProps {
    dish: DishData;
    disabled: boolean;
    sections: SectionData[];
    action: (model: DishCreateModel, id?: string, sectionId?: string) => void;
    isOpen: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement>) => void;
    forCopy?: boolean;
}

export default function DishManagementDialog(props: DishManagementDialogProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const categoryDetails = useAppSelector((state) => state.category.categoryDetails);
    const restaurant = useAppSelector((state) => state.restaurantDetails.restaurantInformation);
    const user = useAppSelector((state) => state.user);
    const taxes = useAppSelector((state) => state.restaurantDetails.taxes);
    const lang = useAppSelector((state) => state.global.language);
    const dishes = useRef<string[]>([]);
    const childRef = useRef<ImageContainerRefProps>(null);
    const scanTries = useRef(0);
    const [showAddIngredientsModal, setShowAddIngredientsModal] = useState(false);
    const [isDeniedTaxSetup, setIsDeniedTaxSetup] = useState(true);
    const [showAdditionalForm, setShowAdditionalForm] = useState(false);
    const [advancedSettingsHasError, setAdvancedSettingsHasError] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [textToEdit, setTextToEdit] = useState('');
    const [showScanner, setShowScanner] = useState(false);
    const [valueName, setValueName] = useState('');

    function saveTextFromEditor(text: string) {
        setTextToEdit(text);
    }

    function preventProp(event: React.MouseEvent<HTMLElement>) {
        event.stopPropagation();
    }

    function onCancel() {
        setShowAdditionalForm(false);
        props.onClose();
    }

    function handleShowAdvancedSettingsError(hasError: boolean) {
        setAdvancedSettingsHasError(hasError);
    }

    function handleScannerClose() {
        setShowScanner(false);
        scanTries.current = 0;
    }

    useEffect(() => {
        setIsDeniedTaxSetup(
            user.restaurants.find((x) => x.id === user.currentRestaurantId)?.currentPosition !==
                Positions.MANAGER
        );
        if (!restaurant.id) {
            dispatch(restaurantStore.actionCreators.getRestaurantInformation());
        }
        if (!categoryDetails) {
            dispatch(categoryStore.actionCreators.getCategoryDetails());
        } else {
            dishes.current = categoryDetails!.dishes.reduce((result: string[], current) => {
                if (current.name !== props.dish.name) {
                    result.push(current.name.toUpperCase());
                }
                return result;
            }, []);
        }
    }, [categoryDetails]);

    useEffect(() => {
        if (props.isOpen && !!!taxes.id) {
            dispatch(restaurantStore.actionCreators.getTaxes());
        }
    }, [props.isOpen]);

    const initalFormValues = {
        name: props.forCopy ? 'copy of ' + props.dish.name : props.dish.name,
        shortName: props.dish.shortName,
        description: props.dish.description,
        shortDescription: props.dish.shortDescription,
        price: props.dish.price!.toFixed(2),
        photo: props.dish.photo,
        section: props.dish.sectionId,
        addToGallery: props.dish.addToGallery,
        category: props.dish.categoryId,
        photoSelected: props.dish.photo.length > 0,
        allergens: props.dish.allergens,
        ingredients: props.dish.ingredients,
        primeCost: props.dish.primeCost?.toFixed(2) ?? 0,
        dineInTax: props.dish.dineInTax ?? TaxType.None,
        homeDeliveryTax: props.dish.homeDeliveryTax ?? TaxType.None,
        takeAwayTax: props.dish.takeAwayTax ?? TaxType.None,
        barcode: props.dish.barcode,
        stockQuantity: props.dish.stockQuantity,
        minimumStock: props.dish.minimumStock,
        notFood: props.dish.notFood,
        color: props.dish.color,
        spicinessLevel: props.dish.spicinessLevel || SpicinessLevels.None,
        diet: props.dish.diet || Diets.None,
        dietaryLaw: props.dish.dietaryLaw || DietaryLaws.None,
        labels: props.dish.labels?.map((label) => label.id) || [],
        size: props.dish.size,
        additionalSizes:
            props.dish.additionalSizes.map((x) => ({ ...x, price: x.price.toFixed(2) })) || [],
    };

    function validateTaxes(name: string, values: any) {
        switch (name) {
            case 'dineInTax':
                if (!restaurant.dineIn.isActive) {
                    return true;
                }
                break;
            case 'homeDeliveryTax':
                if (!restaurant.homeDelivery.isActive) {
                    return true;
                }
                break;
            case 'takeAwayTax':
                if (!restaurant.takeAway.isActive) {
                    return true;
                }
                break;
            default:
                return true;
        }
        if (restaurant.dineIn.isActive && values.dineInTax !== TaxType.None) {
            return true;
        }
        if (restaurant.takeAway.isActive && values.takeAwayTax !== TaxType.None) {
            return true;
        }
        if (restaurant.homeDelivery.isActive && values.homeDeliveryTax !== TaxType.None) {
            return true;
        }
        return false;
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !dishes.current.includes(value?.toUpperCase())
            ),
        shortName: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Short name',
            })
        ),
        price: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .positive(i18n.t('form.errors.greaterZero'))
            .test('is-decimal', i18n.t('form.errors.twoDigitsAfterComma'), (val?: number) => {
                if (val) {
                    return regexHelper.twoDigisAfterComma.test(val.toString());
                }
                return true;
            })
            .required(
                i18n.t('form.errors.required', {
                    name: 'Price',
                })
            ),
        primeCost: yup
            .number()
            .test('is-decimal', i18n.t('form.errors.twoDigitsAfterComma'), (val?: number) => {
                if (val) {
                    return regexHelper.twoDigisAfterComma.test(val.toString());
                }
                return true;
            })
            .typeError(i18n.t('form.errors.onlyDigits'))
            .min(0, i18n.t('form.errors.greaterZero')),
        category: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Work Area',
            })
        ),
        section: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Section',
            })
        ),
        dineInTax: yup
            .string()
            .test(
                'selectsCheck',
                i18n.t('form.errors.atLeasOneTaxSelected'),
                (value: any, context: any) => validateTaxes('dineInTax', context.parent)
            ),
        homeDeliveryTax: yup
            .string()
            .test(
                'selectsCheck',
                i18n.t('form.errors.atLeasOneTaxSelected'),
                (value: any, context: any) => validateTaxes('homeDeliveryTax', context.parent)
            ),
        takeAwayTax: yup
            .string()
            .test(
                'selectsCheck',
                i18n.t('form.errors.atLeasOneTaxSelected'),
                (value: any, context: any) => validateTaxes('takeAwayTax', context.parent)
            ),
        barcode: yup
            .string()
            .matches(
                regexHelper.lettersAndNumbersOnly,
                i18n.t('form.errors.lettersAndNumbersOnly')
            ),
        stockQuantity: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .min(0, i18n.t('form.errors.greaterZero')),
        minimumStock: yup
            .number()
            .typeError(i18n.t('form.errors.onlyDigits'))
            .min(0, i18n.t('form.errors.greaterZero')),
        size: yup.string().when('additionalSizes', {
            is: (value: []) => !!value.length,
            then: yup.string().required(
                i18n.t('form.errors.required', {
                    name: 'Size',
                })
            ),
            otherwise: yup.string().notRequired(),
        }),
        additionalSizes: yup.array().of(
            yup.object().shape({
                size: yup.string().required(
                    i18n.t('form.errors.required', {
                        name: i18n.t('common.size'),
                    })
                ),
                price: yup
                    .number()
                    .typeError(i18n.t('form.errors.onlyDigits'))
                    .positive(i18n.t('form.errors.greaterZero'))
                    .test(
                        'is-decimal',
                        i18n.t('form.errors.twoDigitsAfterComma'),
                        (val?: number) => {
                            if (val) {
                                return regexHelper.twoDigisAfterComma.test(val.toString());
                            }
                            return true;
                        }
                    )
                    .required(
                        i18n.t('form.errors.required', {
                            name: i18n.t('common.price'),
                        })
                    ),
            })
        ),
    });

    function formDisabled(values: any) {
        let result = !props.forCopy;
        if (!props.forCopy && values) {
            result = JSON.stringify(initalFormValues) === JSON.stringify(values);
        }
        return result;
    }

    function addToAllergens(
        ingredients: IngredientModel[],
        values: any,
        setFieldValue: (key: string, value: any) => void
    ) {
        const newAllergens: string[] = [...values.allergens];
        ingredients.forEach((eachIngredient) => {
            eachIngredient.allergens.forEach((allergen) => {
                if (!newAllergens.includes(allergen)) {
                    newAllergens.push(allergen);
                }
            });
        });
        setFieldValue('allergens', newAllergens);
    }

    return (
        <>
            <Dialog
                maxWidth={showAdditionalForm ? 'xl' : 'lg'}
                open={props.isOpen}
                onClick={preventProp}
                aria-labelledby="form-dialog-title"
                className={clsx(classes.dialog, {
                    [classes.dialogExtended]: showAdditionalForm,
                })}
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={clsx(classes.dialogTitle, {
                        [classes.dialogTitleExtended]: showAdditionalForm,
                    })}
                >
                    {props.disabled ? props.dish.name : i18n.t('common.editItem')}{' '}
                    <div onClick={() => setShowAdditionalForm(true)}>
                        {advancedSettingsHasError && !showAdditionalForm ? (
                            <div className={classes.hasError}>
                                <h5>
                                    <img src={images.icons.settingsError} alt="settings" />
                                    {i18n.t('common.advancedSettings')}
                                </h5>
                                <span>{i18n.t('form.errors.hasIncorrectData')}</span>
                            </div>
                        ) : (
                            <h5>
                                <img src={images.icons.settings} alt="settings" />
                                {i18n.t('common.advancedSettings')}
                            </h5>
                        )}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initalFormValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            childRef?.current
                                ?.uploadImage()
                                .then((photo) => {
                                    values.photo = photo;
                                    let price = values.price.replaceAll(' ', '');
                                    const model: DishCreateModel = {
                                        name: trimLeftAndRight(values.name),
                                        shortName: values.shortName,
                                        description: trimLeftAndRight(values.description),
                                        shortDescription: trimLeftAndRight(values.shortDescription),
                                        price: +price,
                                        photo: values.photo,
                                        sectionId: values.section,
                                        addToGallery: values.addToGallery,
                                        categoryId: values.category,
                                        allergens: values.allergens,
                                        ingredients: values.ingredients,
                                        primeCost: +values.primeCost,
                                        dineInTax: values.dineInTax,
                                        homeDeliveryTax: values.homeDeliveryTax,
                                        takeAwayTax: values.takeAwayTax,
                                        barcode: values.barcode,
                                        stockQuantity: values.stockQuantity,
                                        minimumStock: values.minimumStock,
                                        notFood: values.notFood,
                                        color: props.dish.color,
                                        spicinessLevel: values.spicinessLevel,
                                        diet: values.diet,
                                        dietaryLaw: values.dietaryLaw,
                                        labels: values.labels,
                                        size: values.size,
                                        additionalSizes: values.additionalSizes.map((x) => ({
                                            ...x,
                                            price: +x.price,
                                        })),
                                    };
                                    props.action(model, props.dish.id);
                                })
                                .then(() => {
                                    resetForm();
                                    props.onClose();
                                })
                                .catch((error) => alert(error));
                        }}
                    >
                        {({
                            values,
                            touched,
                            setFieldValue,
                            errors,
                            submitForm,
                            setFieldError,
                        }) => (
                            <Form className={classes.root} style={{ textAlign: 'start' }}>
                                <>
                                    <div className={classes.formWrapper}>
                                        <div
                                            className={clsx(classes.formEdit, {
                                                [classes.formEditExtended]: showAdditionalForm,
                                            })}
                                        >
                                            <div className={classes.photoContainer}>
                                                <ImageContainer
                                                    disabled={props.disabled}
                                                    ref={childRef}
                                                    photo={values.photo}
                                                    onChange={(selected, fileUrl) => {
                                                        setFieldValue('photoSelected', selected);
                                                        setFieldValue('photo', fileUrl);
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    visibility: props.disabled
                                                        ? 'hidden'
                                                        : 'visible',
                                                    textAlign: 'start',
                                                    padding: '9px 0 8px 0',
                                                }}
                                            >
                                                <Input
                                                    disabled={!values.photoSelected}
                                                    component={FormikFields.CheckboxWithLabel}
                                                    variant="filled"
                                                    checked={values.addToGallery}
                                                    name="addToGallery"
                                                    type="checkbox"
                                                    Label={{
                                                        label: i18n.t('form.addPhotoToGallery'),
                                                    }}
                                                    placeholder={i18n.t('form.addPhotoToGallery')}
                                                    color="primary"
                                                />
                                            </div>
                                            <h6 className={classes.sectionTitle}>
                                                {i18n.t('common.mainInformation')}
                                            </h6>
                                            <Input
                                                disabled={props.disabled}
                                                variant="filled"
                                                fullWidth={true}
                                                name="name"
                                                type="text"
                                                error={errors.name}
                                                label={i18n.t('form.itemName')}
                                                placeholder={i18n.t('form.itemNamePlaceholder')}
                                            />
                                            <Input
                                                disabled={props.disabled}
                                                variant="filled"
                                                name="shortName"
                                                error={errors.shortName}
                                                type="text"
                                                label={i18n.t('form.shortName')}
                                                placeholder={i18n.t('form.shortNamePlaceholder')}
                                            />
                                            <Input
                                                disabled={props.disabled}
                                                variant="filled"
                                                name="shortDescription"
                                                error={errors.shortDescription}
                                                type="text"
                                                label={i18n.t('form.shortDescription')}
                                                placeholder={i18n.t(
                                                    'form.shortDescriptionPlaceholder'
                                                )}
                                            />
                                            <FormControl
                                                fullWidth
                                                variant="filled"
                                                style={{
                                                    textAlign: 'start',
                                                    marginBottom: 28,
                                                }}
                                                disabled={props.disabled}
                                            >
                                                <InputLabel htmlFor="filled-adornment-description">
                                                    {i18n.t('form.description')}
                                                </InputLabel>
                                                <NativeInput
                                                    id="filled-adornment-description"
                                                    type="text"
                                                    onClick={() => {
                                                        setTextToEdit(values.description);
                                                        setValueName('description');
                                                        setShowEditor(true);
                                                    }}
                                                    readOnly
                                                    value={truncateString(
                                                        values.description.replace(
                                                            regexHelper.removeAllTags,
                                                            ''
                                                        ),
                                                        55
                                                    )}
                                                    endAdornment={
                                                        !props.disabled ? (
                                                            <InputAdornment position="end">
                                                                <IconButton aria-label="pen">
                                                                    <img
                                                                        src={images.icons.penBlack}
                                                                        alt="pen-black"
                                                                        className={
                                                                            classes.resetSearchIcon
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ) : null
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        <div
                                            className={clsx(classes.formEdit, {
                                                [classes.formEditExtended]: showAdditionalForm,
                                            })}
                                        >
                                            <div>
                                                <h6
                                                    className={classes.sectionTitle}
                                                    style={{ marginTop: 0 }}
                                                >
                                                    {i18n.t('navigationTabs.tax')}
                                                </h6>
                                                <ServiceTaxSelect
                                                    service={OrderServices.DineIn}
                                                    selectedTax={values.dineInTax}
                                                    taxes={taxes}
                                                    onSelect={(tax: TaxType) =>
                                                        setFieldValue('dineInTax', tax)
                                                    }
                                                    disabled={
                                                        props.disabled ||
                                                        isDeniedTaxSetup ||
                                                        !restaurant.dineIn.isActive
                                                    }
                                                    error={errors.dineInTax}
                                                />
                                                <ServiceTaxSelect
                                                    service={OrderServices.TakeAway}
                                                    selectedTax={values.takeAwayTax}
                                                    taxes={taxes}
                                                    onSelect={(tax: TaxType) =>
                                                        setFieldValue('takeAwayTax', tax)
                                                    }
                                                    disabled={
                                                        props.disabled ||
                                                        isDeniedTaxSetup ||
                                                        !restaurant.takeAway.isActive
                                                    }
                                                    error={errors.takeAwayTax}
                                                />
                                                <ServiceTaxSelect
                                                    service={OrderServices.HomeDelivery}
                                                    selectedTax={values.homeDeliveryTax}
                                                    taxes={taxes}
                                                    onSelect={(tax: TaxType) =>
                                                        setFieldValue('homeDeliveryTax', tax)
                                                    }
                                                    styles={{ marginBottom: 0 }}
                                                    disabled={
                                                        props.disabled ||
                                                        isDeniedTaxSetup ||
                                                        !restaurant.homeDelivery.isActive
                                                    }
                                                    error={errors.homeDeliveryTax}
                                                />
                                            </div>
                                            <div>
                                                <h6 className={classes.sectionTitle}>
                                                    {i18n.t('common.other')}
                                                </h6>
                                                <Autocomplete
                                                    id="section"
                                                    disabled={true}
                                                    options={props.sections.map((x) => x.id)}
                                                    getOptionLabel={(option) =>
                                                        props.sections.find((x) => x.id === option)
                                                            ?.name ?? ''
                                                    }
                                                    value={values.section}
                                                    getOptionSelected={(option, value) =>
                                                        option === value
                                                    }
                                                    onChange={(e, value) => {
                                                        setFieldValue('section', value || '');
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            name="section"
                                                            variant="filled"
                                                            fullWidth
                                                            label={
                                                                Boolean(
                                                                    touched.section &&
                                                                        errors.section
                                                                )
                                                                    ? i18n.t(
                                                                          'form.errors.required',
                                                                          {
                                                                              name: 'Section',
                                                                          }
                                                                      )
                                                                    : i18n.t('common.section')
                                                            }
                                                            error={Boolean(
                                                                touched.section && errors.section
                                                            )}
                                                        />
                                                    )}
                                                />
                                                <Autocomplete
                                                    id="category"
                                                    disabled={props.disabled}
                                                    options={
                                                        categoryDetails?.categories.map(
                                                            (x) => x.id
                                                        ) || []
                                                    }
                                                    getOptionLabel={(option) =>
                                                        categoryDetails?.categories.find(
                                                            (x) => x.id === option
                                                        )?.name ?? ''
                                                    }
                                                    value={values.category}
                                                    getOptionSelected={(option, value) =>
                                                        option === value
                                                    }
                                                    onChange={(e, value) =>
                                                        setFieldValue('category', value || '')
                                                    }
                                                    renderInput={(params) => (
                                                        <>
                                                            <TextField
                                                                {...params}
                                                                name="categories"
                                                                label={
                                                                    Boolean(
                                                                        touched.category &&
                                                                            errors.category
                                                                    )
                                                                        ? i18n.t(
                                                                              'form.errors.required',
                                                                              {
                                                                                  name: 'Work Area',
                                                                              }
                                                                          )
                                                                        : i18n.t(
                                                                              'restaurant.workArea'
                                                                          )
                                                                }
                                                                variant="filled"
                                                                fullWidth
                                                                error={Boolean(
                                                                    touched.category &&
                                                                        errors.category
                                                                )}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <AllergensSelect
                                                    values={values.allergens}
                                                    onChange={(vals: string[]) =>
                                                        setFieldValue('allergens', vals)
                                                    }
                                                    styles={{ marginBottom: 28 }}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    className={classes.manageIngredientButton}
                                                    disabled={props.disabled}
                                                    onClick={() =>
                                                        !props.disabled &&
                                                        setShowAddIngredientsModal(true)
                                                    }
                                                >
                                                    {`+ ${i18n
                                                        .t('restaurant.manageIngredients')
                                                        .toUpperCase()}`}
                                                </Button>
                                                <AddIngredientsModal
                                                    defaultIngredients={props.dish.ingredients}
                                                    isVisible={showAddIngredientsModal}
                                                    onClose={() => {
                                                        setShowAddIngredientsModal(false);
                                                    }}
                                                    onSave={(ingredients) => {
                                                        addToAllergens(
                                                            ingredients,
                                                            values,
                                                            setFieldValue
                                                        );
                                                        setFieldValue('ingredients', ingredients);
                                                        setShowAddIngredientsModal(false);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {showAdditionalForm && (
                                            <div
                                                className={clsx(classes.formEdit, {
                                                    [classes.formEditExtended]: showAdditionalForm,
                                                })}
                                            >
                                                <h4
                                                    className={classes.additionalHeader}
                                                    onClick={() => setShowAdditionalForm(false)}
                                                >
                                                    <img src={images.icons.cross} alt="cross" />{' '}
                                                    {i18n.t('common.advancedSettings')}
                                                </h4>
                                                <div
                                                    className={classes.flexRowSpaceBetween}
                                                    style={{ marginTop: 18, marginBottom: 28 }}
                                                >
                                                    <Input
                                                        disabled={props.disabled}
                                                        variant="filled"
                                                        fullWidth={true}
                                                        name="barcode"
                                                        type="text"
                                                        label={i18n.t('form.barcode')}
                                                        error={errors.barcode}
                                                        placeholder={i18n.t('form.barcode')}
                                                        style={{
                                                            marginTop: 0,
                                                            marginBottom: 0,
                                                        }}
                                                    />
                                                    {!props.disabled && (
                                                        <IconButton>
                                                            <img
                                                                className={classes.barcodeIcon}
                                                                onClick={() => setShowScanner(true)}
                                                                src={images.icons.barcodeScanIcon}
                                                                alt="barcode-scaner-icon"
                                                            />
                                                        </IconButton>
                                                    )}
                                                </div>

                                                <Input
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    fullWidth={false}
                                                    name="primeCost"
                                                    type="text"
                                                    label={i18n.t('form.primeCost')}
                                                    error={errors.primeCost}
                                                    placeholder={i18n.t('form.primeCost')}
                                                />
                                                <Input
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    fullWidth={true}
                                                    name="stockQuantity"
                                                    type="text"
                                                    label={i18n.t('form.stockQuantity')}
                                                    error={errors.stockQuantity}
                                                    placeholder={i18n.t('form.stockQuantity')}
                                                />
                                                <Input
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    fullWidth={true}
                                                    name="minimumStock"
                                                    type="text"
                                                    label={i18n.t('form.minimumStock')}
                                                    error={errors.minimumStock}
                                                    placeholder={i18n.t('form.minimumStock')}
                                                />
                                                <Input
                                                    component={FormikFields.CheckboxWithLabel}
                                                    disabled={props.disabled}
                                                    variant="filled"
                                                    name="notFood"
                                                    type="checkbox"
                                                    Label={{ label: i18n.t('form.notFood') }}
                                                    placeholder={i18n.t('form.notFood')}
                                                    inputProps={{
                                                        'aria-label': 'secondary checkbox',
                                                    }}
                                                    color="primary"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.formWrapper}>
                                        <Input
                                            className={clsx(classes.formEdit, {
                                                [classes.formEditExtended]: showAdditionalForm,
                                            })}
                                            disabled={props.disabled}
                                            variant="filled"
                                            name="size"
                                            type="text"
                                            error={errors.size}
                                            label={i18n.t('common.size')}
                                            placeholder={
                                                !!values.additionalSizes.length
                                                    ? i18n.t('common.sizeRequired')
                                                    : i18n.t('common.sizeOptional')
                                            }
                                        />
                                        <Input
                                            className={clsx(classes.formEdit, {
                                                [classes.formEditExtended]: showAdditionalForm,
                                            })}
                                            disabled={props.disabled}
                                            variant="filled"
                                            name="price"
                                            type="text"
                                            error={errors.price}
                                            label={i18n.t('form.price')}
                                            placeholder={i18n.t('form.price')}
                                        />
                                        {showAdditionalForm && (
                                            <div className={classes.formEditExtended}></div>
                                        )}
                                    </div>
                                    <FieldArray
                                        name="additionalSizes"
                                        render={(arrayHelpers) => (
                                            <>
                                                {values.additionalSizes.map((size, index) => {
                                                    const sizeName = `additionalSizes[${index}].size`;
                                                    const sizeError = getIn(errors, sizeName);
                                                    const priceName = `additionalSizes[${index}].price`;
                                                    const priceError = getIn(errors, priceName);
                                                    return (
                                                        <div
                                                            className={classes.formWrapper}
                                                            key={index}
                                                        >
                                                            <Input
                                                                className={clsx(classes.formEdit, {
                                                                    [classes.formEditExtended]:
                                                                        showAdditionalForm,
                                                                })}
                                                                disabled={props.disabled}
                                                                variant="filled"
                                                                name={sizeName}
                                                                type="text"
                                                                error={sizeError}
                                                                label={i18n.t('common.size')}
                                                                placeholder={i18n.t(
                                                                    'common.sizeRequired'
                                                                )}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment
                                                                            position="start"
                                                                            onClick={() =>
                                                                                !props.disabled &&
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <IconButton aria-label="pen">
                                                                                <RemoveCircleOutlineIcon
                                                                                    fontSize="small"
                                                                                    style={{
                                                                                        color: 'rgba(0, 0, 0, 0.54)',
                                                                                    }}
                                                                                />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                            <Input
                                                                className={clsx(classes.formEdit, {
                                                                    [classes.formEditExtended]:
                                                                        showAdditionalForm,
                                                                })}
                                                                disabled={props.disabled}
                                                                variant="filled"
                                                                name={priceName}
                                                                type="text"
                                                                error={priceError}
                                                                label={i18n.t('form.price')}
                                                                placeholder={i18n.t('form.price')}
                                                            />
                                                            {showAdditionalForm && (
                                                                <div
                                                                    className={
                                                                        classes.formEditExtended
                                                                    }
                                                                ></div>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                                {!props.disabled && (
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        className={clsx(
                                                            classes.addMoreSizesButton,
                                                            {
                                                                [classes.addMoreSizesButtonExtended]:
                                                                    showAdditionalForm,
                                                            }
                                                        )}
                                                        onClick={() =>
                                                            arrayHelpers.push({
                                                                size: '',
                                                                price: 0,
                                                            })
                                                        }
                                                    >
                                                        {`+ ${i18n
                                                            .t('common.moreSizes')
                                                            .toUpperCase()}`}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    />

                                    <div
                                        className={clsx(classes.bottomPanel, {
                                            [classes.bottomPanelExtended]: showAdditionalForm,
                                        })}
                                    >
                                        <div
                                            className={classes.bottomContainer}
                                            style={{
                                                flexGrow: 0.9,
                                                justifyContent: 'flex-start',
                                            }}
                                        >
                                            <DishDietarySelect<SpicinessLevels>
                                                name={i18n.t('form.spicinessLevel')}
                                                value={values.spicinessLevel}
                                                map={getSpicinessLevels()}
                                                disabled={props.disabled}
                                                onChange={(value: SpicinessLevels) =>
                                                    setFieldValue('spicinessLevel', value)
                                                }
                                            />
                                            <DishDietarySelect<Diets>
                                                name={i18n.t('form.diets')}
                                                value={values.diet}
                                                map={getDiets()}
                                                disabled={props.disabled}
                                                onChange={(value: Diets) =>
                                                    setFieldValue('diet', value)
                                                }
                                            />
                                            <LabelSelect
                                                selectedLabels={values.labels}
                                                onChange={(labels: string[]) =>
                                                    setFieldValue('labels', labels)
                                                }
                                                disabled={props.disabled}
                                            />
                                            <DishDietarySelect<DietaryLaws>
                                                name={i18n.t('form.dietaryLaws')}
                                                value={values.dietaryLaw}
                                                map={getDietaryLaws()}
                                                disabled={props.disabled}
                                                onChange={(value: DietaryLaws) =>
                                                    setFieldValue('dietaryLaw', value)
                                                }
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            {props.disabled ? (
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    onClick={onCancel}
                                                >
                                                    {i18n.t('button.close')}
                                                </Button>
                                            ) : (
                                                <>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        onClick={onCancel}
                                                        className={clsx(classes.mRight10, {
                                                            [classes.smallFont]: lang === 'de',
                                                        })}
                                                    >
                                                        {i18n.t('button.cancel')}
                                                    </Button>
                                                    <Button
                                                        disabled={formDisabled(values)}
                                                        onClick={submitForm}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {i18n.t('button.save')}
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                                <HandleFormikHelper action={handleShowAdvancedSettingsError} />
                                <SetFieldText field={valueName} text={textToEdit} />
                                <Dialog open={showScanner} onClose={() => setShowScanner(false)}>
                                    <DialogContent>
                                        <BarcodeScannerComponent
                                            width="100%"
                                            height="100%"
                                            onUpdate={(_error, result) => {
                                                scanTries.current++;
                                                if (result) {
                                                    setFieldValue('barcode', result.getText());
                                                    handleScannerClose();
                                                } else if (scanTries.current > 19) {
                                                    setFieldError(
                                                        'barcode',
                                                        i18n.t('form.errors.noBarcodeDetected')
                                                    );
                                                    scanTries.current = 0;
                                                    handleScannerClose();
                                                }
                                            }}
                                        />
                                    </DialogContent>
                                </Dialog>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
            <TextEditor
                isOpen={showEditor}
                text={textToEdit}
                onClose={() => setShowEditor(false)}
                onSave={saveTextFromEditor}
            />
        </>
    );
}

function HandleFormikHelper({ action }: { action: (hasError: boolean) => void }) {
    const { errors } = useFormikContext();
    useEffect(() => {
        const hasError =
            'barcode' in errors ||
            'primeCost' in errors ||
            'stockQuantity' in errors ||
            'minimumStock' in errors;
        action(hasError);
    }, [errors]);
    return null;
}

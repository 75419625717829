import React, { useRef, useState } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TextField as MuiTextField,
    Typography,
} from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import i18n from 'i18n-js';
import { useSelector } from 'react-redux';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';

import { images } from 'assets';
import { ApplicationState } from 'store';
import { createDateHistoryList, getChartDataSet, getChartLabels, isChartStacked } from './logic';
import { DateHistoryData } from './index';

import styles from './styles';
import { helperFunctions } from '../../../helpers';
import { Text } from '../../shared';
import { FinanceData, FinanceDishData } from 'store/financeStore/reducer';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface FinanceItemDetailsProps {
    financeItem: FinanceData;
}

export const FinanceItemDetails = ({ financeItem }: FinanceItemDetailsProps) => {
    const [expanded, setExpanded] = useState<boolean[]>([]);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [textFilters, setTextFilters] = useState(['', '', '', '']);
    const classes = styles();

    function getTotalPriceOfEach(dishes: FinanceDishData[]) {
        return dishes
            .reduce((a: number, b: FinanceDishData) => a + b.price * b.number, 0)
            .toFixed(2);
    }

    function filterDataBySearchText(dish: {
        name: string | number;
        price: number;
        number: number;
    }) {
        let passed = true;
        for (let i = 0; i < 3; i++) {
            let val: string | number = '';
            switch (i) {
                case 0:
                    val = dish.name;
                    break;
                case 1:
                    val = dish.price;
                    break;
                case 2:
                    val = dish.number;
                    break;
                case 3:
                    val = (dish.price * dish.number).toFixed(2);
                    break;
            }
            const hasPassed = val.toString().toLowerCase().indexOf(textFilters[i]) >= 0;
            if (!hasPassed) {
                passed = false;
                break;
            }
        }
        return passed;
    }

    function setSearchTextFilter(text: string, index: number) {
        const newFilters = [...textFilters];
        newFilters[index] = text;
        setTextFilters(newFilters);
    }

    return (
        <>
            <Table
                aria-label="simple table"
                className={`${classes.noBordersHeader} ${classes.mTop10}`}
            >
                <TableHead>
                    <TableCell
                        style={{
                            width: '32%',
                        }}
                        component={'td'}
                        align={'left'}
                    >
                        <MuiTextField
                            className={
                                focusedIndex === 1 ? classes.focusedTextField : classes.textField
                            }
                            onFocus={() => setFocusedIndex(1)}
                            onBlur={() => setFocusedIndex(0)}
                            onChange={(e) => setSearchTextFilter(e.target.value, 0)}
                            placeholder={i18n.t('restaurant.product')}
                            style={{ width: '100px' }}
                            InputProps={{
                                endAdornment: (
                                    <img
                                        src={images.icons.search}
                                        className={classes.searchIcon}
                                        alt="seach-icon"
                                    />
                                ),
                                disableUnderline: true,
                            }}
                        />
                    </TableCell>
                    <TableCell style={{ width: '25%' }} component={'td'}>
                        <MuiTextField
                            className={
                                focusedIndex === 2 ? classes.focusedTextField : classes.textField
                            }
                            onFocus={() => setFocusedIndex(2)}
                            onBlur={() => setFocusedIndex(0)}
                            onChange={(e) => setSearchTextFilter(e.target.value, 1)}
                            placeholder={i18n.t('restaurant.productPrice')}
                            style={{ width: '140px' }}
                            InputProps={{
                                endAdornment: (
                                    <img
                                        src={images.icons.search}
                                        className={classes.searchIcon}
                                        alt="search-icon"
                                    />
                                ),
                                disableUnderline: true,
                            }}
                        />
                    </TableCell>
                    <TableCell style={{ width: '25%' }} component={'td'}>
                        <MuiTextField
                            className={
                                focusedIndex === 3 ? classes.focusedTextField : classes.textField
                            }
                            onFocus={() => setFocusedIndex(3)}
                            onBlur={() => setFocusedIndex(0)}
                            onChange={(e) => setSearchTextFilter(e.target.value, 2)}
                            placeholder={helperFunctions.truncateLangString(
                                i18n.t('restaurant.numberOfOrders'),
                                17
                            )}
                            style={{ width: '170px' }}
                            InputProps={{
                                endAdornment: (
                                    <img
                                        src={images.icons.search}
                                        alt="search-icon"
                                        className={classes.searchIcon}
                                    />
                                ),
                                disableUnderline: true,
                            }}
                        />
                    </TableCell>
                    <TableCell style={{ width: '18%' }} component={'td'}>
                        <MuiTextField
                            className={
                                focusedIndex === 4 ? classes.focusedTextField : classes.textField
                            }
                            onFocus={() => setFocusedIndex(4)}
                            onBlur={() => setFocusedIndex(0)}
                            onChange={(e) => setSearchTextFilter(e.target.value, 3)}
                            placeholder={i18n.t('form.cancelled')}
                            style={{ width: '130px' }}
                            InputProps={{
                                endAdornment: (
                                    <img
                                        src={images.icons.search}
                                        alt="search-icon"
                                        className={classes.searchIcon}
                                    />
                                ),
                                disableUnderline: true,
                            }}
                        />
                    </TableCell>
                    <TableCell style={{ width: '18%' }} component={'td'} align={'right'}>
                        <MuiTextField
                            className={
                                focusedIndex === 5 ? classes.focusedTextField : classes.textField
                            }
                            onFocus={() => setFocusedIndex(5)}
                            onBlur={() => setFocusedIndex(0)}
                            onChange={(e) => setSearchTextFilter(e.target.value, 4)}
                            placeholder={i18n.t('common.totalAmount')}
                            style={{ width: '130px' }}
                            InputProps={{
                                endAdornment: (
                                    <img
                                        src={images.icons.search}
                                        alt="search-icon"
                                        className={classes.searchIcon}
                                    />
                                ),
                                disableUnderline: true,
                            }}
                        />
                    </TableCell>
                </TableHead>
            </Table>
            {financeItem.services?.map(
                (
                    service: {
                        name: string;
                        totalAmount: number;
                        dishes: FinanceDishData[];
                        cancelledDishes: FinanceDishData[];
                    },
                    i
                ) => (
                    <Accordion
                        classes={classes}
                        className={classes.accordion}
                        onChange={(x) => {
                            const newExpanded = [...expanded];
                            newExpanded[i] = expanded[i] !== undefined ? !expanded[i] : false;
                            setExpanded(newExpanded);
                        }}
                        expanded={expanded[i] !== undefined ? expanded[i] : true}
                    >
                        <AccordionSummary
                            className={classes.accordionBGColor}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                        >
                            <Typography className={classes.centeredTypography}>
                                {i18n.t(`form.${service.name}`)}
                            </Typography>
                            <div className={classes.priceAmountContainer}>
                                <span className={classes.currencyText}>
                                    {i18n.t('common.currencies.chf')}
                                </span>
                                <span className={classes.currencyAmount}>
                                    {getTotalPriceOfEach(service.dishes)}
                                </span>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                            <Table aria-label="simple table">
                                {service.dishes.filter(filterDataBySearchText).map((dish) => (
                                    <TableRow>
                                        <TableCell
                                            style={{ width: '32%' }}
                                            component={'td'}
                                            align={'left'}
                                        >
                                            <Text
                                                text={dish.name.trimLeft()}
                                                fontSize={14}
                                                maxLength={25}
                                            />
                                        </TableCell>
                                        <TableCell style={{ width: '25%' }} component={'td'}>
                                            {dish.price}
                                        </TableCell>
                                        <TableCell style={{ width: '25%' }} component={'td'}>
                                            {dish.number}
                                        </TableCell>
                                        <TableCell>
                                            {service.cancelledDishes.find(
                                                (x) => x.name === dish.name
                                            )?.number || 0}
                                        </TableCell>
                                        <TableCell
                                            style={{ width: '18%' }}
                                            component={'td'}
                                            align={'right'}
                                        >
                                            {(dish.price * dish.number).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                )
            )}
        </>
    );
};

interface ChartsProps {
    dateFilter: number;
    onDateClick: (
        selectedDates: {
            startDate: Date;
            endDate: Date;
        },
        dateFilter: number,
        dateHistoryList: DateHistoryData[]
    ) => void;
    selectedDatesHistory: DateHistoryData[];
}

export const Charts = ({ dateFilter, onDateClick, selectedDatesHistory }: ChartsProps) => {
    const financeOrders = useSelector((state: ApplicationState) => state.finance.financeOrders);
    const classes = styles();
    const [selectedServices, setSelectedServices] = useState([true, true, true]);
    const [secondaryDateFilter, setSecondaryDateFilter] = useState(0);
    const [showFakeData] = useState(false);

    const chartRef = useRef(null);

    const labels = getChartLabels(dateFilter, secondaryDateFilter, selectedDatesHistory);
    const options = {
        onClick: (evt: any, elements: any, myChart: any) => {
            const points = myChart.getElementsAtEventForMode(
                evt,
                'nearest',
                { intersect: true },
                true
            );

            if (points.length) {
                const firstPoint = points[0];

                // const label = myChart.data.labels[firstPoint.index];
                // const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];

                onXAxisClick(firstPoint.index);
            }
        },
        onHover: (event: any, chartElement: any) => {
            if (chartElement.length === 1) {
                event.native.target.style.cursor = 'pointer';
            }
            if (chartElement.length === 0) {
                event.native.target.style.cursor = 'default';
            }
        },
        plugins: {
            legend: {
                onClick: (e: any, legendItem: any, legend: any) => {
                    const index = legendItem.datasetIndex;
                    switchCheckbox(index);
                    hideLegendItem(legend, index);
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: isChartStacked(dateFilter, secondaryDateFilter),
            },
            y: {
                stacked: isChartStacked(dateFilter, secondaryDateFilter),
            },
        },
    };
    const data = {
        labels: labels.map((label) => label.name),
        datasets: getChartDataSet(
            selectedServices,
            labels,
            financeOrders,
            dateFilter,
            secondaryDateFilter,
            showFakeData
        ),
        hover: {
            mode: 'label',
        },
    };

    function hideLegendItem(legend: any, index: number) {
        let ci = legend.chart;
        [ci.getDatasetMeta(index)].forEach(function (meta) {
            meta.hidden = selectedServices[index];
        });
        ci.update();
    }

    function switchCheckbox(index: number) {
        // @ts-ignore
        hideLegendItem(chartRef?.current?.legend, index);
        const newSelectedServices = [...selectedServices];
        newSelectedServices[index] = !selectedServices[index];
        setSelectedServices(newSelectedServices);
    }

    function getCheckboxList() {
        const list = [];
        for (let i = 1; i <= 4; i++) {
            let name = '',
                image;
            switch (i) {
                case 1:
                    name = 'form.homeDelivery';
                    image = images.icons.chartHomeDelivery;
                    break;
                case 2:
                    name = 'form.takeAway';
                    image = images.icons.chartTakeAway;
                    break;
                case 3:
                    name = 'form.dineIn';
                    image = images.icons.chartDineIn;
                    break;
                case 4:
                    name = 'form.cancelled';
                    image = images.icons.cancelled;
                    break;
            }
            list.push(
                <>
                    <Checkbox
                        onChange={() => switchCheckbox(i - 1)}
                        className={`${i > 1 && classes.mLeft20}`}
                        checked={selectedServices[i - 1]}
                        color={'primary'}
                    />
                    <img src={image} alt="chart-icon" />
                    <span className={classes.chartBottomIconText}>{i18n.t(name)}</span>
                </>
            );
        }
        return list;
    }

    function getSecondaryDateFilters() {
        if (dateFilter === 3) {
            return (
                <div>
                    <Button
                        onClick={() => setSecondaryDateFilter(0)}
                        className={classes.mRight20}
                        color="primary"
                        variant={secondaryDateFilter === 0 ? 'contained' : 'text'}
                    >
                        {i18n.t('common.days')}
                    </Button>
                    <Button
                        onClick={() => setSecondaryDateFilter(1)}
                        color="primary"
                        variant={secondaryDateFilter === 1 ? 'contained' : 'text'}
                    >
                        {i18n.t('common.weeks')}
                    </Button>
                </div>
            );
        }
    }

    function onXAxisClick(clickedIndex: number) {
        const clickedDate = labels[clickedIndex].date;
        const clickedMonthDay = clickedDate.getDate();

        let endDate = new Date(),
            startDate = clickedDate,
            dateHistoryList: any[] = [],
            newDateFilter = dateFilter - 1;

        const lastHistoryDate = selectedDatesHistory[selectedDatesHistory.length - 1].startDate;

        const lastHistoryYear = lastHistoryDate.getFullYear();
        const lastHistoryMonth = lastHistoryDate.getMonth();

        switch (dateFilter) {
            case 1:
                return;
            case 2:
                if (selectedDatesHistory.length) {
                    const selectedDayNumber = clickedDate.getDate();

                    startDate = new Date(lastHistoryYear, lastHistoryMonth, selectedDayNumber);
                    endDate = new Date(
                        lastHistoryDate.getFullYear(),
                        lastHistoryDate.getMonth(),
                        selectedDayNumber + 1,
                        20,
                        59
                    );

                    dateHistoryList = [...selectedDatesHistory];
                    dateHistoryList.push({
                        name: `${selectedDayNumber} ${i18n.t(
                            'common.monthNames.' + lastHistoryMonth
                        )}`,
                        startDate,
                        endDate,
                    });
                }
                newDateFilter = 1;
                break;
            case 3:
                let weekCount = clickedIndex + 1;
                if (secondaryDateFilter === 0) {
                    if (selectedDatesHistory.length) {
                        startDate = new Date(lastHistoryYear, lastHistoryMonth, clickedMonthDay);
                        endDate = new Date(lastHistoryYear, lastHistoryMonth, clickedMonthDay + 1);
                        dateHistoryList = [...selectedDatesHistory];
                        dateHistoryList.push({
                            name: `${clickedMonthDay} ${i18n.t(
                                'common.monthNames.' + lastHistoryDate.getMonth()
                            )} ${lastHistoryYear}`,
                            startDate,
                            endDate,
                        });
                    } else {
                        dateHistoryList = createDateHistoryList(4, clickedDate, weekCount);
                    }
                    newDateFilter = 1;
                } else {
                    weekCount = clickedIndex + 1;

                    endDate = new Date(lastHistoryYear, lastHistoryMonth, clickedIndex + 1);

                    if (selectedDatesHistory.length) {
                        startDate = new Date(
                            lastHistoryYear,
                            lastHistoryMonth,
                            clickedDate.getDate()
                        );
                        endDate = new Date(
                            lastHistoryYear,
                            lastHistoryMonth,
                            clickedDate.getDate() + 7
                        );

                        dateHistoryList = [...selectedDatesHistory];
                        dateHistoryList.push({
                            name: `${weekCount} ${i18n.t('common.week')}`,
                            startDate,
                            endDate,
                        });
                    } else {
                        dateHistoryList = createDateHistoryList(3, clickedDate, weekCount);
                    }
                }
                break;
            case 4:
                setSecondaryDateFilter(1);
                endDate = new Date(clickedDate.getFullYear(), clickedDate.getMonth() + 1, 1);
                dateHistoryList = createDateHistoryList(2, clickedDate);
        }

        onDateClick(
            {
                startDate,
                endDate,
            },
            newDateFilter,
            dateHistoryList
        );
    }

    return (
        <>
            <div className={classes.chartContainer}>
                <span className={classes.chartCurrencyText}>{i18n.t('common.currencies.chf')}</span>
                <Bar id={'chartContainer'} ref={chartRef} options={options} data={data} />
            </div>
            <div className={classes.chartBottomContainer}>
                {getCheckboxList()}
                {/*<div className={classes.mLeft20}>
                    <Checkbox
                        onChange={() => setShowFakeData(!showFakeData)}
                        checked={showFakeData}
                        color={'primary'}
                    />
                    <span className={classes.chartBottomIconText}>Show Fake Data</span>
                </div>*/}
                <div className={classes.chartSecondaryFilterContainer}>
                    {getSecondaryDateFilters()}
                </div>
            </div>
        </>
    );
};

interface SelectedDateHistoryProps {
    dateHistory: DateHistoryData[];
    onClick: (item: { name: string; startDate: Date; endDate: Date; index: number }) => void;
}

export const SelectedDateHistory = ({ dateHistory, onClick }: SelectedDateHistoryProps) => {
    const classes = styles();

    function getElements() {
        const elements: any = [];

        dateHistory.forEach((eachItem, i) => {
            const { name } = eachItem;

            if (i === 0) {
                elements.push(
                    <div
                        key={i}
                        className={classes.cursorHover}
                        onClick={() =>
                            onClick({
                                ...eachItem,
                                index: i,
                            })
                        }
                    >
                        {name}
                    </div>
                );
            } else {
                elements.push(
                    <div
                        key={i}
                        className={`${classes.flexRow} ${classes.cursorHover}`}
                        onClick={() =>
                            onClick({
                                ...eachItem,
                                index: i,
                            })
                        }
                    >
                        <img
                            src={images.icons.rightArrow}
                            alt="right-arrow"
                            className={classes.mHorizontal15}
                        />
                        <span className={classes.secondDateHistoryText}>{name}</span>
                    </div>
                );
            }
        });

        return elements;
    }

    return <div className={classes.flexRow}>{getElements()}</div>;
};

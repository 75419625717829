import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import i18n from 'i18n-js';
import { Form, Formik } from 'formik';
import * as FormikFields from 'formik-material-ui';
import { IconButton, TextField as MuiTextField } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as yup from 'yup';

import { images } from 'assets';
import { regexHelper } from 'helpers/regexHelper';
import { CustomPhoneInput, Input, ImageContainer } from 'components/shared';
import { helperFunctions } from 'helpers';
import { getOrderPreparationDurations } from '../functions';
import { truncateString } from 'helpers/helperFunctions';
import { ApplicationState } from 'store';
import { CuisineData } from 'store/restaurantDetailsStore/reducer';
import styles from '../styles';
import AddWorkingHoursModal from './AddWorkingHoursModal';
import TextEditor from 'components/shared/components/TextEditor';
import SetFieldText from 'components/shared/components/SetFieldText';
import Services from './Services';
import { HourData, Values, DayAndService } from './types';
interface RestaurantDetailsProps {
    restaurantInformation: any;
    formikRef: React.Ref<any>;
    logoUploadRef: React.Ref<any>;
    photoUploadRef: React.Ref<any>;
    onFormChange: () => void;
}

export default function RestaurantDetails(props: RestaurantDetailsProps) {
    const cuisineTypes = useSelector(
        (state: ApplicationState) => state.restaurantDetails.cuisineTypes
    );
    const classes = styles();
    const restaurantOpenHoursRef: any = React.useRef(null);
    const [initialValues, setInitialValues] = useState({
        name: props.restaurantInformation.name,
        addressLine: props.restaurantInformation.addressLine,
        zip: props.restaurantInformation.zip,
        city: props.restaurantInformation.city,
        phoneNumber: props.restaurantInformation.phoneNumber,
        email: props.restaurantInformation.email,
        description: props.restaurantInformation.description,
        logoUrl: props.restaurantInformation.logoUrl,
        photoUrl: props.restaurantInformation.photoUrl,
        website: props.restaurantInformation.website,
        facebook: props.restaurantInformation.facebook,
        instagram: props.restaurantInformation.instagram,
        saturday: props.restaurantInformation.saturday || [],
        sunday: props.restaurantInformation.sunday || [],
        type: props.restaurantInformation.type || [],
        addToGallery: props.restaurantInformation.addToGallery || false,
        ordersDeadline: props.restaurantInformation.ordersDeadline || 30,
        longitude: props.restaurantInformation.longitude,
        latitude: props.restaurantInformation.latitude,
        dineIn: props.restaurantInformation.dineIn || {},
        homeDelivery: props.restaurantInformation.homeDelivery || {},
        takeAway: props.restaurantInformation.takeAway || {},
        timeZone: props.restaurantInformation.timeZone,
    });

    const [workingHoursModal, setWorkingHoursModal] = useState<DayAndService | undefined>();

    const [, setRestaurantLogo] = useState('');
    const [showEditor, setShowEditor] = useState(false);
    const [textToEdit, setTextToEdit] = useState('');
    const [valueName, setValueName] = useState('');

    function saveTextFromEditor(text: string) {
        setTextToEdit(text);
        props.onFormChange();
    }

    const [width, setWidth] = useState(0);
    const divRef = useRef<HTMLDivElement>(null);

    function updateWidth() {
        if (divRef.current) {
            setWidth(divRef?.current?.offsetWidth);
        }
    }

    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    useEffect(() => {
        initialValues.name !== props.restaurantInformation.name &&
            setInitialValues({
                name: props.restaurantInformation.name,
                addressLine: props.restaurantInformation.addressLine,
                zip: props.restaurantInformation.zip,
                city: props.restaurantInformation.city,
                phoneNumber: props.restaurantInformation.phoneNumber,
                email: props.restaurantInformation.email,
                description: props.restaurantInformation.description,
                logoUrl: props.restaurantInformation.logoUrl,
                photoUrl: props.restaurantInformation.photoUrl,
                website: props.restaurantInformation.website,
                facebook: props.restaurantInformation.facebook,
                instagram: props.restaurantInformation.instagram,
                saturday: props.restaurantInformation.saturday || [],
                sunday: props.restaurantInformation.sunday || [],
                type: props.restaurantInformation.type || [],
                addToGallery: props.restaurantInformation.addToGallery || false,
                ordersDeadline: props.restaurantInformation.ordersDeadline,
                longitude: props.restaurantInformation.longitude,
                latitude: props.restaurantInformation.latitude,
                dineIn: props.restaurantInformation.dineIn || {},
                homeDelivery: props.restaurantInformation.homeDelivery || {},
                takeAway: props.restaurantInformation.takeAway || {},
                timeZone: props.restaurantInformation.timeZone,
            });

        const strVer = JSON.stringify(props.restaurantInformation.openHours);
        if (strVer && restaurantOpenHoursRef.current === null) {
            restaurantOpenHoursRef.current = JSON.parse(strVer);
        }
    }, [props.restaurantInformation.name]); // eslint-disable-line

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .max(
                128,
                i18n.t('form.errors.maxLength', {
                    name: 'Name',
                    length: 128,
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            ),
        addressLine: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Address line',
            })
        ),
        email: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Email',
                })
            )
            .email(i18n.t('form.errors.email')),
        phoneNumber: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Phone number',
                })
            )
            .matches(regexHelper.phoneNumber, i18n.t('form.errors.phoneNumber')),
        zip: yup
            .string()
            .max(10)
            .required(
                i18n.t('form.errors.required', {
                    name: i18n.t('paymentDetails.zip'),
                })
            ),
        city: yup.string().matches(regexHelper.city, i18n.t('form.errors.city')),
    });

    function displayWorkingHoursModal(params: DayAndService) {
        setWorkingHoursModal(params);
    }
    /**
     * Hides working hours modal
     * and before that cleans the empty hours to avoid validation issues for backend
     */
    function hideWorkingHoursModal() {
        setWorkingHoursModal(undefined);
    }

    function onSaveWorkingHours(hours: HourData[]) {
        if (!workingHoursModal) return;
        // @ts-ignore
        const values = props.formikRef?.current?.values as Values;
        // @ts-ignore
        const setFieldValue = props.formikRef?.current?.setFieldValue;
        setFieldValue(workingHoursModal?.service, {
            ...values[workingHoursModal?.service],
            workingHours: {
                ...values[workingHoursModal?.service].workingHours,
                [workingHoursModal.dayName]: hours,
            },
        });

        hideWorkingHoursModal();
        props.onFormChange();
    }

    function getInitHours() {
        if (workingHoursModal) {
            // @ts-ignore
            return props.formikRef?.current?.values[workingHoursModal?.service]?.workingHours[
                workingHoursModal?.dayName
            ];
        } else {
            return [];
        }
    }

    function hasAnyService(values: Values) {
        const { homeDelivery, dineIn, takeAway } = values;
        if (homeDelivery.isActive || takeAway.isActive || dineIn.isActive) {
            return true;
        }
        return false;
    }

    return (
        <div className={classes.container}>
            <AddWorkingHoursModal
                isVisible={!!workingHoursModal}
                onClose={hideWorkingHoursModal}
                onSave={onSaveWorkingHours}
                service={workingHoursModal?.service}
                daykey={workingHoursModal?.dayName}
                initHours={getInitHours()}
            />
            <Formik
                innerRef={props.formikRef}
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async () => {}}
            >
                {({ values, setFieldValue, errors, setFieldError }) => (
                    <>
                        <div
                            key="first-div"
                            onChange={() => {
                                props.onFormChange();
                                !hasAnyService(values) && setFieldError('dine', 'required');
                            }}
                            className={classes.restaurantDetailsLeftContainer}
                        >
                            <div className={classes.flexRowContainer} key="image" ref={divRef}>
                                <ImageContainer
                                    ref={props.logoUploadRef}
                                    photo={values.logoUrl}
                                    onChange={(_selected, fileUrl) => {
                                        setRestaurantLogo(fileUrl);
                                        setFieldValue('logoUrl', fileUrl);
                                        props.onFormChange();
                                    }}
                                    styles={{ marginBottom: 0, width: '48%' }}
                                    text={i18n.t('restaurant.uploadLogo').toUpperCase()}
                                />
                                <ImageContainer
                                    ref={props.photoUploadRef}
                                    photo={values.photoUrl}
                                    onChange={(_selected, fileUrl) => {
                                        setRestaurantLogo(fileUrl);
                                        setFieldValue('photoUrl', fileUrl);
                                        props.onFormChange();
                                    }}
                                    styles={{ marginBottom: 0, width: '48%' }}
                                    text={i18n.t('restaurant.uploadRestaurantPhoto').toUpperCase()}
                                />
                            </div>

                            <Form
                                className={classes.formContainer}
                                onChange={props.onFormChange}
                                key="form-group"
                            >
                                <div className={classes.flexRowSpaceBetween}>
                                    <h6 className={classes.sectionTitle}>
                                        {i18n.t('restaurant.restaurantInformation').toUpperCase()}
                                    </h6>
                                    <div className={classes.makePhotoMainCheckbox}>
                                        <Input
                                            component={FormikFields.CheckboxWithLabel}
                                            variant="filled"
                                            checked={values.addToGallery}
                                            name={'addToGallery'}
                                            key="addToGallery"
                                            type="checkbox"
                                            Label={{ label: i18n.t('form.addPhotoToGallery') }}
                                            placeholder={i18n.t('form.addPhotoToGallery')}
                                            color="primary"
                                        />
                                    </div>
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <Input
                                        error={errors.name}
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.restaurantName')}
                                        name="name"
                                        key="name"
                                        type="text"
                                        label={i18n.t('form.restaurantName')}
                                        variant="filled"
                                    />
                                    <Input
                                        error={errors.addressLine}
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.restaurantAddress')}
                                        name="addressLine"
                                        key="addressLine"
                                        type="text"
                                        label={i18n.t('form.restaurantAddress')}
                                        variant="filled"
                                    />
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <CustomPhoneInput
                                        width={'44.5%'}
                                        placeholder={i18n.t('form.phoneNumber')}
                                        value={values.phoneNumber}
                                        onChange={(val: string) =>
                                            setFieldValue('phoneNumber', val)
                                        }
                                        // @ts-ignore
                                        invalid={errors.phoneNumber}
                                    />
                                    <div className={classes.halfFormInputContainer}>
                                        <Input
                                            className={classes.halfFormInput}
                                            placeholder={i18n.t('form.zip')}
                                            name="zip"
                                            key="zip"
                                            type="text"
                                            label={i18n.t('form.zip')}
                                            variant="filled"
                                        />
                                        <Input
                                            className={classes.halfFormInput}
                                            placeholder={i18n.t('form.city')}
                                            name="city"
                                            key="city"
                                            type="text"
                                            label={i18n.t('form.city')}
                                            variant="filled"
                                        />
                                    </div>
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <Input
                                        error={errors.email}
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.email')}
                                        name="email"
                                        key="email"
                                        type="text"
                                        label={i18n.t('form.email')}
                                        variant="filled"
                                    />
                                    <Input
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.facebook')}
                                        name="facebook"
                                        key="facebook"
                                        value={values.facebook || ''}
                                        type="text"
                                        label={i18n.t('form.facebook')}
                                        variant="filled"
                                    />
                                </div>
                                <div className={classes.flexRowContainer}>
                                    <Input
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.webpage')}
                                        name="website"
                                        key="website"
                                        type="text"
                                        label={i18n.t('form.webpage')}
                                        variant="filled"
                                    />
                                    <Input
                                        className={classes.formInput}
                                        placeholder={i18n.t('form.instagram')}
                                        name="instagram"
                                        key="instagram"
                                        value={values.instagram || ''}
                                        type="text"
                                        label={i18n.t('form.instagram')}
                                        variant="filled"
                                    />
                                </div>
                                <Input
                                    as="textarea"
                                    fullWidth
                                    name="description"
                                    key="description"
                                    type="text"
                                    variant="filled"
                                    multiline
                                    minRows={4}
                                    disabled
                                    value={truncateString(
                                        values.description?.replace(
                                            regexHelper.removeAllTags,
                                            ''
                                        ) || '',
                                        300
                                    )}
                                    label={
                                        <div
                                            className={classes['flexRowSpaceBetween']}
                                            style={{
                                                width: values.description
                                                    ? width * 1.3
                                                    : width - 28,
                                            }}
                                        >
                                            <span>
                                                {i18n.t('form.detailedDescription')}
                                                {'    '}
                                                {!values.description
                                                    ? `(${i18n.t(
                                                          'form.detailedPlaceHolderDescription'
                                                      )})`
                                                    : ''}
                                            </span>
                                            <IconButton aria-label="pen">
                                                <img
                                                    src={images.icons.penBlack}
                                                    alt="pen-black"
                                                    className={classes.resetSearchIcon}
                                                />
                                            </IconButton>
                                        </div>
                                    }
                                    onClick={() => {
                                        setTextToEdit(values.description);
                                        setValueName('description');
                                        setShowEditor(true);
                                    }}
                                />
                                <SetFieldText field={valueName} text={textToEdit} />
                            </Form>
                        </div>
                        <div
                            key="second-div"
                            className={classes.restaurantDetailsRightContainer}
                            onChange={props.onFormChange}
                        >
                            <h6 className={classes.sectionTitle}>
                                {i18n.t('restaurant.typeOfCuisine').toUpperCase()}
                            </h6>
                            <Autocomplete
                                options={cuisineTypes}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                classes={{
                                    option: classes.dropdownItem,
                                    listbox: classes.listBox,
                                    paper: classes.paper,
                                }}
                                renderOption={(option) => {
                                    const exists = !!values.type.find(
                                        (currentOption: CuisineData) =>
                                            currentOption.id === option.id
                                    );
                                    return (
                                        <div
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                props.onFormChange();
                                                let newValues;
                                                if (exists) {
                                                    newValues = values.type.filter(
                                                        (selectedOption: CuisineData) =>
                                                            selectedOption.id !== option.id
                                                    );
                                                } else {
                                                    newValues = [...values.type, option];
                                                }
                                                setFieldValue('type', newValues);
                                            }}
                                            className={`${classes.width100} ${classes.bottomLine} ${
                                                exists ? classes.selectedDropdownItem : ''
                                            }`}
                                        >
                                            <Checkbox color="primary" checked={exists} />
                                            <span>{option.name}</span>
                                        </div>
                                    );
                                }}
                                renderInput={(params) => (
                                    <>
                                        <MuiTextField
                                            {...params}
                                            name="type"
                                            label={
                                                !values?.type?.length
                                                    ? i18n.t('common.chooseTheType')
                                                    : `${values.type.length} ${i18n.t(
                                                          'restaurant.cuisines'
                                                      )}`
                                            }
                                            error={values.type.length === 0}
                                            variant="filled"
                                        />
                                    </>
                                )}
                            />
                            <h6 className={`${classes.sectionTitle} ${classes.mTop5P}`}>
                                {i18n.t('restaurant.maxTimeForOrder').toUpperCase()}
                            </h6>
                            <Autocomplete
                                options={getOrderPreparationDurations()}
                                getOptionLabel={(option) => option.name}
                                value={{
                                    id: values.ordersDeadline / 30,
                                    duration: values.ordersDeadline,
                                    name:
                                        values.ordersDeadline + ` ${i18n.t('restaurant.minutes')}`,
                                }}
                                getOptionSelected={(option, value) => {
                                    return option.id === value.id;
                                }}
                                classes={{
                                    option: classes.dropdownItem,
                                    listbox: classes.listBox,
                                    paper: classes.paper,
                                }}
                                onChange={(e, val) => {
                                    if (val) {
                                        setFieldValue('ordersDeadline', val?.duration);
                                        props.onFormChange();
                                    }
                                }}
                                renderOption={(option) => {
                                    return (
                                        <div
                                            style={{ padding: '10px' }}
                                            className={`${classes.width100} ${classes.bottomLine}`}
                                        >
                                            <span>{option.name}</span>
                                        </div>
                                    );
                                }}
                                renderInput={(params) => (
                                    <>
                                        <MuiTextField
                                            {...params}
                                            label={i18n.t('restaurant.duration')}
                                            variant="filled"
                                        />
                                    </>
                                )}
                            />

                            <div
                                className={helperFunctions.renderMultipleClassNames([
                                    classes.pTop5P,
                                ])}
                            >
                                <h6 className={classes.sectionTitle}>{i18n.t('form.services')}</h6>
                            </div>

                            <Services
                                values={values}
                                onClick={displayWorkingHoursModal}
                                onChangeActive={(service, isActive) => {
                                    setFieldValue(service, { ...values[service], isActive });
                                }}
                            />
                        </div>
                    </>
                )}
            </Formik>
            <TextEditor
                isOpen={showEditor}
                text={textToEdit}
                onClose={() => setShowEditor(false)}
                onSave={saveTextFromEditor}
            />
        </div>
    );
}

import i18n from 'i18n-js';
import _ from 'lodash';

import agent from 'api/agent';
import {
    EmptyOrderModel,
    OrderItemStatusChangeModel,
    OrderUpdateModel,
    PositionOrderItemStatusChangeModel,
} from 'api/models';
import { OrderStatus } from 'constants/enums';
import globalStore from '../globalStore';
import { AppThunkAction } from 'store';
import { OrdersAction } from './actions';
import { OrderDetails, OverdudedOrder, OrderReadyDishes, OrderItem } from './reducer';

export const actionCreators = {
    getOrdersList:
        (
            searchString: string = '',
            dateFrom: string | undefined = undefined,
            dateTo: string | undefined = undefined,
            page: number = 0,
            limit: number = 13,
            asc: boolean = false,
            orderBy: string = '',
            position: string = ''
        ): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_ORDERS_LIST_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.GetOrdersList(
                searchString,
                dateFrom,
                dateTo,
                page,
                limit,
                asc,
                orderBy,
                position
            )
                .then((res) => {
                    dispatch({
                        type: 'GET_ORDERS_LIST_SUCCESS',
                        list: res.list,
                        count: res.count,
                        overduded: false,
                    });
                    dispatch({
                        type: 'SET_ORDER_DETAILS_ID',
                        id: res.list[0]?.id,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_ORDERS_LIST_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateOrdersList:
        (
            searchString: string = '',
            dateFrom: string | undefined = undefined,
            dateTo: string | undefined = undefined,
            page: number = 0,
            limit: number = 13,
            asc: boolean = false,
            orderBy: string = ''
        ): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_ORDERS_LIST_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.GetOrdersList(searchString, dateFrom, dateTo, page, limit, asc, orderBy)
                .then((res) => {
                    dispatch({
                        type: 'UPDATE_ORDERS_LIST_SUCCESS',
                        list: res.list,
                        count: res.count,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_ORDERS_LIST_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    setOrderDetailsId:
        (id: string): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_ORDER_DETAILS_ID',
                id: id,
            });
        },

    changeOrderItemStatus:
        (model: OrderItemStatusChangeModel): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'CHANGE_ORDER_ITEM_STATUS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.ChangeOrderItemStatus(model)
                .then((res) => {
                    dispatch({
                        type: 'CHANGE_ORDER_ITEM_STATUS_SUCCESS',
                        order: res,
                        itemId: model.itemId,
                        dailyMenuId: model.dailyMenuId,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'CHANGE_ORDER_ITEM_STATUS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    updateOrderStatus:
        (id: string, status: OrderStatus): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_ORDER_STATUS',
                id: id,
                status: status,
            });
        },

    setSearchString:
        (data: string): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_SEARCH_STRING',
                data: data,
            });
        },

    getNewOrders:
        (position: string): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_NEW_ORDERS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.GetNewOrdersCount(position)
                .then((res) => {
                    dispatch({
                        type: 'GET_NEW_ORDERS_SUCCESS',
                        newOrders: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_NEW_ORDERS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getPositionOrders:
        (position: string): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_POSITION_ORDERS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.GetPositionOrders(position)
                .then((res) => {
                    dispatch({
                        type: 'GET_POSITION_ORDERS_SUCCESS',
                        data: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_POSITION_ORDERS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    createEmptyOrder:
        (model: EmptyOrderModel): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'CREATE_EMPTY_ORDER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.CreateEmptyOrder(model)
                .then((res) => {
                    dispatch({
                        type: 'CREATE_EMPTY_ORDER_SUCCESS',
                        data: res,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'CREATE_EMPTY_ORDER_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    changePositionOrderStatus:
        (model: PositionOrderItemStatusChangeModel): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'CHANGE_POSITION_ORDER_STATUS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.ChangeOrderItemStatus(model)
                .then(() => {
                    dispatch({
                        type: 'CHANGE_POSITION_ORDER_STATUS_SUCCESS',
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'CHANGE_POSITION_ORDER_STATUS_ERROR',
                        orderId: model.orderId,
                        previousStatus: model.previousStatus,
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateOrder:
        (id: string, model: OrderUpdateModel, action?: () => void): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_ORDER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.UpdateOrder(id, model)
                .then((res) => {
                    dispatch({
                        type: 'UPDATE_ORDER_SUCCESS',
                        order: res,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                    action && action();
                })
                .catch((e) => {
                    dispatch({
                        type: 'UPDATE_ORDER_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getOrderDetails:
        (
            id: string,
            setState?: (order: OrderDetails) => void,
            setInitialState?: (order: OrderDetails) => void
        ): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_ORDER_DETAILS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.GetOrder(id)
                .then((res) => {
                    dispatch({
                        type: 'GET_ORDER_DETAILS_SUCCESS',
                        order: res,
                    });
                    setState && setState({ ...res, orderItems: _.cloneDeep(res.orderItems) });
                    if (setInitialState) {
                        setInitialState({ ...res, orderItems: _.cloneDeep(res.orderItems) });
                    }
                })
                .catch((e) => {
                    dispatch({
                        type: 'GET_ORDER_DETAILS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    setOrderDetails:
        (order: OrderDetails, keepPrevious?: boolean): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_ORDER_DETAILS',
                order: order,
                keepPrevious: keepPrevious,
            });
        },
    resetOrderDetails: (): AppThunkAction<OrdersAction> => (dispatch) => {
        dispatch({
            type: 'RESET_ORDER_DETAILS',
        });
    },
    getOverdudedOrders: (): AppThunkAction<OrdersAction> => (dispatch) => {
        agent.Order.GetOverdudedOrders().then((result) => {
            dispatch({
                type: 'SET_OVERDUDED_ORDERS',
                orders: result,
            });
        });
    },
    setOverdudedOrders:
        (orders: OverdudedOrder[]): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_OVERDUDED_ORDERS',
                orders: orders,
            });
        },
    setShowSearch:
        (showSearch: boolean, toggle: boolean = false): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_SHOW_SEARCH',
                showSearch: showSearch,
                toggle: toggle,
            });
        },
    prepareGettingDetailedOverdudedOrders:
        (orders: OverdudedOrder[]): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_SEARCH_STRING',
                data: orders.map((x) => x.orderNumber).join(', '),
            });
            dispatch({
                type: 'SET_SHOW_SEARCH',
                showSearch: true,
                toggle: false,
            });
        },
    getDetailedOverdudedOrders:
        (ordersNumbers: OverdudedOrder[]): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'GET_ORDERS_LIST_START',
            });
            agent.Order.GetOverdudedOrdersDetails(ordersNumbers.map((x) => x.orderNumber)).then(
                (result) => {
                    dispatch({
                        type: 'GET_ORDERS_LIST_SUCCESS',
                        list: result.list,
                        count: result.count,
                        overduded: true,
                    });
                }
            );
        },
    updateOrdersReadyDishes:
        (orders: OrderReadyDishes): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'UPDATE_READY_ORDERS_DISHES',
                data: orders,
            });
        },
    setSelectedDish:
        (
            dishId: string,
            selectedDailyMenuId: string | undefined = undefined
        ): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'SET_SELECTED_DISH',
                data: dishId,
                selectedDailyMenuId,
            });
        },
    addItemToOrder:
        (item: OrderItem, amount = 1, action?: () => void): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'ADD_ITEM_TO_ORDER',
                item,
                amount,
                action,
            });
        },
    assignOrder:
        (orderId: string, restaurantId: string, userId: string): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'ORDER_SET_RESPONSIBLE_USER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Order.AssignOrder(orderId, restaurantId, userId)
                .then((res) => {
                    dispatch({
                        type: 'ORDER_SET_RESPONSIBLE_USER_SUCCESS',
                        order: res,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'ORDER_SET_RESPONSIBLE_USER_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    addItemToEditedOrder:
        (
            item: OrderItem | null,
            decrease: boolean = false,
            changeIngredients: boolean = false
        ): AppThunkAction<OrdersAction> =>
        (dispatch) => {
            dispatch({
                type: 'ORDER_ADD_ITEM_TO_ADD',
                item: item,
                decrease: decrease,
                changeIngredients: changeIngredients,
            });
        },
};

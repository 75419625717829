import React from 'react';

import i18n from 'i18n-js';

import { images } from 'assets';
import NewOrdersCounter from './NewOrdersCounter';
import { NavbarList } from './NavbarList';
import NavbarListItem from './NavbarListItem';
import { Positions } from 'constants/enums';

interface RestaurantEmployeeNavbarProps {
    isOpen: boolean;
    position: Positions;
}

export default function RestaurantEmployeeNavbar(props: RestaurantEmployeeNavbarProps) {
    return (
        <NavbarList isOpen={props.isOpen}>
            <NavbarListItem
                isOpen={props.isOpen}
                path="/orders"
                icon={images.icons.penNavbar}
                title={i18n.t('navigationTabs.orders')}
            />
            <NewOrdersCounter isOpen={props.isOpen} />
            <NavbarListItem
                isOpen={props.isOpen}
                path="/menu"
                icon={images.icons.menu}
                title={i18n.t('navigationTabs.menu')}
            />
            {props.position === Positions.WAITER && (
                <NavbarListItem
                    isOpen={props.isOpen}
                    path="/transactions"
                    icon={images.icons.receiptLong}
                    title={i18n.t('navigationTabs.transactions')}
                />
            )}
        </NavbarList>
    );
}

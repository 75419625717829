import React, { useRef, useState, useEffect } from 'react';

import i18n from 'i18n-js';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import {
    Avatar,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField as MuiTextField,
    makeStyles,
    Theme,
    createStyles,
    alpha,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { parsePhoneNumber } from 'react-phone-number-input';
import ObjectID from 'bson-objectid';

import { RestaurantUserCreateModel, UserData } from 'store/usersStore/reducer';
import { ApplicationState } from 'store';
import financeStore from 'store/financeStore';
import { default as restaurantStore } from 'store/restaurantDetailsStore';
import { regexHelper } from 'helpers/regexHelper';
import { Roles, OrderServices } from 'constants/enums';
import agent from 'api/agent';
import { getCoordinatesFromTheAddress, trimLeftAndRight } from 'helpers/helperFunctions';

import QR from '../../restaurantInformation/qr';
import Finance from '../../restaurantInformation/finance';
import { AddRestaurantUserForm } from '../../users/components';
import {
    CustomPhoneInput,
    Input,
    PopupConfirmation,
    SectionTab,
    Text,
    UserRoleLabel,
    ImageContainer,
} from 'components/shared';
import globalStore from 'store/globalStore';
import AddNewUserModal from './components/AddNewUserModal';
import RestaurantUsers from './components/RestaurantsUsers';
import CreateRestaurantHeader from './components/CreateRestaurantHeader';
import UsersTable from './components/UsersTable';
import commonStyles from 'config/commonStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        bottomLine: {
            padding: '3px 0',
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
        },
        listBox: {
            marginTop: 0,
            padding: 0,
        },
        dropdownItem: {
            padding: 0,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: alpha(theme.palette.primary.main, 0.16),
            },
        },
        selectedDropdownItem: {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
        paper: {
            marginTop: 0,
        },
        usersDialog: {
            overflowY: 'auto',
            '& .MuiDialog-paperWidthLg': {
                maxWidth: 936,
                minWidth: 936,
            },
        },
        percentageString: {
            position: 'absolute',
            left: '30px',
            top: '21px',
            color: 'red',
        },
        formInnerContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingLeft: '40px',
            //width: '1075px',
        },
        leftContainer: {
            width: '67%',
        },
        sectionTitle: {
            fontSize: '10px',
            fontWeight: 'normal',
            letterSpacing: '1.25px',
            marginTop: '30px',
        },
        inputContainer: {
            marginBottom: 30,
            width: '100%',
            //width: '348px',
            /* '& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled': {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.secondary.light,
            }, */
        },
        phoneInput: {
            margin: '0',
            height: '35px',
        },
        halfFormInputContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 30,
        },
        halfFormInput: {
            width: '48%',
        },
        percentageInput: {
            width: '164px',
            marginRight: '20px',
        },
        rightContainer: {
            width: '28%',
            padding: '0 10px',
            backgroundColor: alpha(theme.palette.secondary.light, 0.3),
            borderLeft: `1px solid ${theme.palette.secondary.light}`,
            height: '480px',
        },
        uploadLogoContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            alignItems: 'center',
            margin: '10% 0 -4.5% 0',
            height: 158,
        },
        logo: {
            width: '95px',
            height: '95px',
            borderRadius: '47.5px',
            objectFit: 'cover',
        },
        uploadLogoTextContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignSelf: 'center',
            width: '160px',
            marginTop: '10px',
            marginLeft: '-8px',
            zIndex: 1,
        },
        contactPersonsContainer: {
            marginTop: '-65px',
        },
        formContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            backgroundColor: theme.palette.common.white,
        },
        dialogTitle: {
            display: 'inline-block',
            marginLeft: 139,
        },
    })
);

export interface RestaurantContactPerson {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export interface RestaurantCreateForm {
    name: string;
    addressLine: string;
    zip: string;
    city: string;
    phoneNumber: string;
    email: string;
    website: string;
    logoUrl: string;
    contactPersons: RestaurantContactPerson[];
}

interface RestaurantCreateData {
    name: string;
    addressLine: string;
    zip: string;
    city: string;
    phoneNumber: string;
    email: string;
    website: string;
    logoUrl: string;
    latitude: number;
    longitude: number;
    percentageForDineIn?: number;
    percentageForTakeAway: number;
    percentageForHomeDelivery: number;
    type: any[];
    timeZone: string;
}

export default function CreateEditRestaurantPage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const cuisineTypes = useSelector(
        (state: ApplicationState) => state.restaurantDetails.cuisineTypes
    );
    const financeItem = useSelector((state: ApplicationState) => state.finance.financeItem);
    const params = useParams<{ id: string }>();
    const [selectedContactPerson, setSelectedContactPerson] = useState<UserData | string>('');
    const [previuosState, setPreviousState] = useState<RestaurantCreateData>({
        name: '',
        addressLine: '',
        zip: '',
        city: '',
        phoneNumber: '',
        email: '',
        website: '',
        logoUrl: '',
        latitude: 0,
        longitude: 0,
        type: [],
        timeZone: '',
        percentageForDineIn: 1,
        percentageForTakeAway: 1,
        percentageForHomeDelivery: 1,
    });

    function handleDownloadQRName(name: string, service: OrderServices) {
        switch (service) {
            case OrderServices.HomeDelivery:
                setDownloadHomeDeliveryQrName(name);
                break;
            case OrderServices.DineIn:
                setDownloadDineInQrName(name);
                break;
            default:
                setDownloadTakeAwayQrName(name);
        }
    }
    useEffect(() => {
        if (ObjectID.isValid(params.id)) {
            getRestaurantDetails();
        } else if (!!params.id) {
            history.goBack();
        }

        if (!cuisineTypes.length) {
            dispatch(restaurantStore.actionCreators.getCuisineTypes());
        }
        return () => {
            dispatch({
                type: 'GET_QR_CODES_SUCCESS',
                data: [],
            });
        };
    }, [params.id]); // eslint-disable-line

    function getRestaurantDetails() {
        agent.Restaurants.GetDetails(params.id).then((response) => {
            updatePreviousState(response);
            setFormValues({
                name: response.name,
                addressLine: response.addressLine,
                zip: response.zip,
                city: response.city,
                phoneNumber: response.phoneNumber,
                email: response.email,
                logoUrl: response.logoUrl,
                website: response.website,
                latitude: response.latitude,
                longitude: response.longitude,
                type: response.type,
                percentageForDineIn: response.percentageForDineIn || 0,
                percentageForTakeAway: response.percentageForTakeAway || 0,
                percentageForHomeDelivery: response.percentageForHomeDelivery || 0,
                timeZone: response.timeZone,
            });
            setLogoToUpload(response.logoUrl ?? '');
            setContactPersons(response.contactPersons);
            setPreviuosContactPersons(response.contactPersons);
            setDownloadTakeAwayQrName(response.qrCodesData.takeAwayQrCodeName);
            setDownloadHomeDeliveryQrName(response.qrCodesData.takeAwayQrCodeName);
            setDownloadDineInQrName(response.qrCodesData.dineInQrCodeName);
        });
    }

    function updatePreviousState(response: any) {
        setPreviousState({
            name: response.name,
            addressLine: response.addressLine,
            zip: response.zip,
            city: response.city,
            phoneNumber: response.phoneNumber,
            email: response.email,
            logoUrl: response.logoUrl,
            website: response.website,
            latitude: response.latitude,
            longitude: response.longitude,
            type: response.type,
            percentageForDineIn: response.percentageForDineIn || 0,
            percentageForTakeAway: response.percentageForTakeAway || 0,
            percentageForHomeDelivery: response.percentageForHomeDelivery || 0,
            timeZone: response.timeZone,
        });
    }

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .max(
                128,
                i18n.t('form.errors.maxLength', {
                    name: 'Name',
                    length: 128,
                })
            )
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            ),
        addressLine: yup.string().required(
            i18n.t('form.errors.required', {
                name: 'Address line',
            })
        ),
        email: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Email',
                })
            )
            .email(i18n.t('form.errors.email')),
        phoneNumber: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Phone number',
                })
            )
            .matches(regexHelper.phoneNumber, i18n.t('form.errors.phoneNumber')),
        zip: yup.string().max(10),
        city: yup.string().matches(regexHelper.city, i18n.t('form.errors.city')),
    });
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const history = useHistory();

    function createContactPerson(model: RestaurantUserCreateModel) {
        dispatch(globalStore.actionCreators.showSpiner());
        agent.Users.CreateRestaurantOwner(model)
            .then((response) => setContactPersons([...contactPersons, response]))
            .catch((e) => dispatch(globalStore.actionCreators.showToaster('error', e)))
            .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
    }

    const createContactPersons = (model: RestaurantContactPerson[], id: string, name: string) => {
        return new Promise((resolve, reject) => {
            const persons = model.map(
                (p) =>
                    ({
                        ...p,
                        restaurantId: id,
                        isContactPerson: true,
                        restaurantName: name,
                    } as RestaurantUserCreateModel)
            );
            try {
                persons.forEach((x) => {
                    agent.Users.CreateRestaurantOwner(x);
                });
                return resolve('Created succesfully!');
            } catch (ex) {
                return reject(ex);
            }
        });
    };
    const removeContactPerson = (user: UserData) => {
        dispatch(globalStore.actionCreators.showSpiner());
        agent.Users.EditRestaurantUser({
            restaurant: {
                id: params.id,
                name: formValues.name,
                addressLine: formValues.addressLine,
                zip: formValues.zip,
                city: formValues.city,
                logoUrl: formValues.logoUrl ?? '',
            },
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            isContactPerson: false,
            positions: user.restaurants.find((x) => x.restaurantId === params.id)!.positions,
            isOwner: user.roles[0].name === Roles.OWNER,
        })
            .then((response) => {
                setContactPersons(contactPersons.filter((x) => x.id !== response.id));
            })
            .catch((e) => dispatch(globalStore.actionCreators.showToaster('error', e)))
            .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
    };
    const fileUploadRef: any = React.useRef(null);

    const [formValues, setFormValues] = useState<RestaurantCreateData>({
        name: '',
        addressLine: '',
        zip: '',
        city: '',
        phoneNumber: '',
        email: '',
        website: '',
        logoUrl: '',
        latitude: 0,
        longitude: 0,
        percentageForDineIn: 1,
        percentageForTakeAway: 1,
        percentageForHomeDelivery: 1,
        type: [],
        timeZone: '',
    });
    const [contactPersons, setContactPersons] = useState<UserData[]>([]);
    const [previuosContactPersons, setPreviuosContactPersons] = useState<UserData[]>([]);
    const [logoToUpload, setLogoToUpload] = useState<Blob | null>(null);
    const [logoHasChanged, setLogoHasChanged] = useState(false);
    const [formChanged, setFormChanged] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showUsersTable, setShowUsersTable] = useState(false);
    const [downloadDineInQrName, setDownloadDineInQrName] = useState<string>('');
    const [downloadTakeAwayQrName, setDownloadTakeAwayQrName] = useState<string>('');
    const [downloadHomeDeliveryQrName, setDownloadHomeDeliveryQrName] = useState<string>('');

    const browserData = useSelector((state: ApplicationState) => state.user.browserData);

    async function lastSubmit(values: RestaurantCreateData) {
        dispatch(globalStore.actionCreators.showSpiner());
        if (
            !params.id ||
            values.latitude === 0 ||
            values.longitude === 0 ||
            values.addressLine !== previuosState.addressLine ||
            values.city !== previuosState.city ||
            values.zip !== previuosState.zip
        ) {
            const { latitude, longitude, timeZone } = await getCoordinatesFromTheAddress(
                `${values.addressLine}, ${values.zip} ${values.city}`
            );
            values.latitude = latitude;
            values.longitude = longitude;
            values.timeZone = timeZone;
        }
        if (values.logoUrl && logoHasChanged) {
            values.logoUrl = await fileUploadRef.current?.uploadImage();
        }

        const cuisineTypes: string[] = [];
        values.type.map((type) => cuisineTypes.push(type.id));
        const newValues = { ...values };
        newValues.type = cuisineTypes;
        if (params.id) {
            agent.Restaurants.Update(params.id, newValues)
                .then((response) => {
                    if (contactPersons.length > previuosContactPersons.length) {
                        let newContactPersons = [...contactPersons];
                        newContactPersons.splice(0, previuosContactPersons.length);
                        return createContactPersons(newContactPersons, response.id, response.name);
                    }
                    setLogoHasChanged(false);
                    setFormChanged(false);
                    updatePreviousState(response);
                    dispatch(
                        globalStore.actionCreators.showToaster(
                            'success',
                            i18n.t('messages.updatedSuccesfully')
                        )
                    );
                    // getRestaurantDetails();
                })
                .catch((e) => {
                    dispatch(globalStore.actionCreators.showToaster('error', e));
                })
                .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
        } else {
            agent.Restaurants.Create(newValues)
                /* .then((response) => {
                    // @ts-ignore
                    return createContactPersons(contactPersons, response.id, response.name);
                }) */
                .then((response) => {
                    //history.goBack();
                    history.push(`/restaurants/${response.id}`);
                })
                .catch((e) => {
                    dispatch(globalStore.actionCreators.showToaster('error', e));
                })
                .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
        }
    }

    const formRef = useRef<any>();
    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    function triggerFormChange() {
        setTimeout(() => {
            if (JSON.stringify(formRef.current.values) !== JSON.stringify(previuosState)) {
                setFormChanged(true);
            } else if (
                formRef.current.values.percentageForDineIn !== previuosState.percentageForDineIn ||
                formRef.current.values.percentageForHomeDelivery !==
                    previuosState.percentageForHomeDelivery ||
                formRef.current.values.percentageForTakeAway !== previuosState.percentageForTakeAway
            ) {
                setFormChanged(true);
            } else {
                setFormChanged(false);
            }
        }, 200);
    }

    const renderTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <>
                        <Formik
                            innerRef={formRef}
                            enableReinitialize={true}
                            initialValues={formValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setFieldValue }) => {
                                const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
                                if (parsedPhoneNumber) {
                                    if (!parsedPhoneNumber.country) {
                                        const fixedPhoneNumber = values.phoneNumber.replace(
                                            '+' + parsedPhoneNumber.countryCallingCode.toString(),
                                            browserData?.country_calling_code
                                        );
                                        setFieldValue('phoneNumber', fixedPhoneNumber);
                                        values.phoneNumber = fixedPhoneNumber;
                                    }
                                }
                                lastSubmit({
                                    ...values,
                                    name: trimLeftAndRight(values.name),
                                    addressLine: trimLeftAndRight(values.addressLine),
                                    city: trimLeftAndRight(values.city),
                                    website: trimLeftAndRight(values.website),
                                });
                            }}
                        >
                            {({ setFieldValue, values, errors }) => (
                                <>
                                    <Form
                                        className={classes.formInnerContainer}
                                        onChange={() => {
                                            triggerFormChange();
                                        }}
                                    >
                                        <div className={classes.leftContainer}>
                                            <h6 className={classes.sectionTitle}>
                                                {i18n
                                                    .t('restaurant.restaurantInformation')
                                                    .toUpperCase()}
                                            </h6>
                                            <div className={classes.flexRowSpaceBetween}>
                                                <div style={{ width: '49%' }}>
                                                    {' '}
                                                    <Input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        error={errors.name}
                                                        variant="filled"
                                                        placeholder={i18n.t('form.restaurantName')}
                                                        label={i18n.t('form.restaurantName')}
                                                        className={classes.inputContainer}
                                                    />
                                                    <CustomPhoneInput
                                                        containerStyle={{
                                                            margin: '0',
                                                            height: '35px',
                                                            marginBottom: 30,
                                                        }}
                                                        invalid={errors.phoneNumber}
                                                        placeholder={i18n.t('form.phoneNumber')}
                                                        value={values.phoneNumber}
                                                        onChange={(val: any) =>
                                                            setFieldValue('phoneNumber', val)
                                                        }
                                                    />{' '}
                                                    <Input
                                                        id="email"
                                                        placeholder={i18n.t('form.email')}
                                                        name="email"
                                                        error={errors.email}
                                                        type="text"
                                                        label={i18n.t('form.email')}
                                                        variant="filled"
                                                        className={classes.inputContainer}
                                                    />
                                                </div>
                                                <div style={{ width: '49%' }}>
                                                    <Input
                                                        id="addressLine"
                                                        placeholder={i18n.t(
                                                            'form.restaurantAddress'
                                                        )}
                                                        name="addressLine"
                                                        error={errors.addressLine}
                                                        type="text"
                                                        label={i18n.t('form.restaurantAddress')}
                                                        variant="filled"
                                                        className={classes.inputContainer}
                                                    />
                                                    <div className={classes.halfFormInputContainer}>
                                                        <Input
                                                            id="zip"
                                                            className={classes.halfFormInput}
                                                            placeholder={i18n.t('form.zip')}
                                                            name="zip"
                                                            error={errors.zip}
                                                            type="text"
                                                            label={i18n.t('form.zip')}
                                                            variant="filled"
                                                        />
                                                        <Input
                                                            id="city"
                                                            className={classes.halfFormInput}
                                                            placeholder={i18n.t('form.city')}
                                                            name="city"
                                                            error={errors.city}
                                                            type="text"
                                                            label={i18n.t('form.city')}
                                                            variant="filled"
                                                        />
                                                    </div>
                                                    <Input
                                                        id="website"
                                                        placeholder={i18n.t('form.webpage')}
                                                        name="website"
                                                        error={errors.website}
                                                        type="text"
                                                        label={i18n.t('form.webpage')}
                                                        variant="filled"
                                                        className={classes.inputContainer}
                                                    />
                                                </div>
                                            </div>

                                            <h6
                                                className={classes.sectionTitle}
                                                style={{ marginTop: 0 }}
                                            >
                                                {i18n.t('restaurant.percentageFor').toUpperCase()}
                                            </h6>
                                            <Input
                                                placeholder={i18n.t('form.dineIn')}
                                                name="percentageForDineIn"
                                                error={errors.percentageForDineIn}
                                                type="number"
                                                label={i18n.t('form.dineIn')}
                                                variant="filled"
                                                className={classes.percentageInput}
                                                maxLength={2}
                                                inputProps={{
                                                    min: 0,
                                                    max: 99,
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.percentageString}
                                                            style={{
                                                                display:
                                                                    values.percentageForDineIn?.toString()
                                                                        .length
                                                                        ? 'flex'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Input
                                                placeholder={i18n.t('form.takeAway')}
                                                name="percentageForTakeAway"
                                                error={errors.percentageForTakeAway}
                                                type="number"
                                                label={i18n.t('form.takeAway')}
                                                variant="filled"
                                                className={classes.percentageInput}
                                                maxLength={2}
                                                inputProps={{
                                                    min: 0,
                                                    max: 99,
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.percentageString}
                                                            style={{
                                                                display:
                                                                    values.percentageForTakeAway.toString()
                                                                        .length
                                                                        ? 'flex'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <Input
                                                placeholder={i18n.t('form.homeDelivery')}
                                                name="percentageForHomeDelivery"
                                                error={errors.percentageForHomeDelivery}
                                                type="number"
                                                label={i18n.t('form.homeDelivery')}
                                                variant="filled"
                                                className={classes.percentageInput}
                                                maxLength={2}
                                                inputProps={{
                                                    min: 0,
                                                    max: 99,
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className={classes.percentageString}
                                                            style={{
                                                                display:
                                                                    values.percentageForHomeDelivery.toString()
                                                                        .length
                                                                        ? 'flex'
                                                                        : 'none',
                                                            }}
                                                        >
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                        <div className={classes.rightContainer}>
                                            <div className={classes.uploadLogoContainer}>
                                                <ImageContainer
                                                    ref={fileUploadRef}
                                                    photo={values.logoUrl}
                                                    onChange={(_, fileUrl) => {
                                                        setFieldValue('logoUrl', fileUrl);
                                                        setLogoHasChanged(true);
                                                        triggerFormChange();
                                                    }}
                                                    styles={{ height: '100%', width: '100%' }}
                                                />
                                            </div>
                                            <h6 className={classes.sectionTitle}>
                                                {i18n.t('restaurant.typeOfCuisine').toUpperCase()}
                                            </h6>
                                            <Autocomplete
                                                options={cuisineTypes}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) =>
                                                    option.id === value.id
                                                }
                                                classes={{
                                                    option: classes.dropdownItem,
                                                    listbox: classes.listBox,
                                                    paper: classes.paper,
                                                }}
                                                renderOption={(option) => {
                                                    const exists = !!values.type.find(
                                                        (currentOption: any) =>
                                                            currentOption.id === option.id
                                                    );
                                                    return (
                                                        <div
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                let newValues;
                                                                if (exists) {
                                                                    newValues = values.type.filter(
                                                                        (selectedOption: any) =>
                                                                            selectedOption.id !==
                                                                            option.id
                                                                    );
                                                                } else {
                                                                    newValues = [
                                                                        ...values.type,
                                                                        option,
                                                                    ];
                                                                }
                                                                setFieldValue('type', newValues);
                                                                triggerFormChange();
                                                            }}
                                                            className={`${classes.width100} ${
                                                                classes.bottomLine
                                                            } ${
                                                                exists
                                                                    ? classes.selectedDropdownItem
                                                                    : ''
                                                            }`}
                                                        >
                                                            <Checkbox
                                                                color="primary"
                                                                checked={exists}
                                                            />
                                                            {option.name}
                                                        </div>
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <>
                                                        <MuiTextField
                                                            {...params}
                                                            name="type"
                                                            label={
                                                                values.type.length === 0
                                                                    ? i18n.t('common.chooseTheType')
                                                                    : `${
                                                                          values.type.length
                                                                      } ${i18n.t(
                                                                          'restaurant.cuisines'
                                                                      )}`
                                                            }
                                                            variant="filled"
                                                            fullWidth
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </Form>
                                </>
                            )}
                        </Formik>
                        <TableContainer className={classes.contactPersonsContainer}>
                            <h6 className={classes.sectionTitle} style={{ marginLeft: 40 }}>
                                {i18n.t('restaurant.contactPerson').toUpperCase()}
                            </h6>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={6} padding="none">
                                            <SectionTab
                                                disabled={!params.id}
                                                text={i18n.t('restaurant.addContactPerson')}
                                                onClick={(
                                                    event: React.MouseEvent<HTMLButtonElement>
                                                ) => {
                                                    /* if (
                                                            !!!params.id &&
                                                            contactPersons.length < 2
                                                        ) {
                                                            setShowAddContactModal(true);
                                                        } else {
                                                            setAnchorEl(event.currentTarget);
                                                        } */
                                                    setAnchorEl(event.currentTarget);
                                                }}
                                            />
                                            <AddNewUserModal
                                                anchorEl={anchorEl}
                                                handleClose={() => setAnchorEl(null)}
                                                openNew={() => setShowAddContactModal(true)}
                                                openExists={() => setShowUsersTable(true)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {contactPersons.map((person: UserData) => (
                                        <TableRow key={person.id}>
                                            <TableCell scope="row">
                                                <List>
                                                    <ListItem alignItems="center">
                                                        <ListItemAvatar>
                                                            <Avatar
                                                                alt="Remy Sharp"
                                                                src="/static/images/avatar/1.jpg"
                                                            />
                                                        </ListItemAvatar>
                                                        <Text
                                                            text={`${person.firstName} ${person.lastName}`}
                                                            maxLength={20}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Text
                                                    text={person.email}
                                                    fontSize={14}
                                                    maxLength={30}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{person.phoneNumber}</TableCell>
                                            <TableCell align="left">
                                                {person.restaurants
                                                    .find((x) => x.restaurantId === params.id)
                                                    ?.positions.join(', ') || '-'}
                                            </TableCell>
                                            <TableCell align="left" style={{ width: 140 }}>
                                                <UserRoleLabel role={person.roles[0].name} />
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={() => {
                                                        setSelectedContactPerson(person);
                                                    }}
                                                >
                                                    <DeleteIcon color={'primary'} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    <PopupConfirmation
                                        open={Object.keys(selectedContactPerson).length > 0}
                                        close={() => setSelectedContactPerson('')}
                                        title={i18n.t('common.deleteUser')}
                                        description={i18n.t('confirmation.deleteUser')}
                                        activeBtn={i18n.t('common.delete')}
                                        action={() =>
                                            typeof selectedContactPerson === 'object' &&
                                            removeContactPerson(selectedContactPerson)
                                        }
                                    />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                );
            case 1:
                return <RestaurantUsers />;
            case 2:
                return <Finance restaurantId={params.id} isOld={true} />;
            case 3:
                return (
                    <QR
                        restaurantId={params.id}
                        isAdmin={true}
                        downloadDineInQrName={downloadDineInQrName}
                        downloadTakeAwayQrName={downloadTakeAwayQrName}
                        downloadHomeDeliveryQrName={downloadHomeDeliveryQrName}
                        setDownloadQrName={handleDownloadQRName}
                    />
                );
            default:
                return <></>;
        }
    };

    function getRestaurantDetailsTabs() {
        /**
         * If there is restaurant to view then we show related tabs
         */
        if (!!params.id) {
            return [
                i18n.t('restaurant.restaurantUsers'),
                i18n.t('restaurant.finance'),
                i18n.t('restaurant.qr'),
            ];
        }
        return [];
    }

    return (
        <>
            <div className={classes.formContainer}>
                <CreateRestaurantHeader
                    tabs={
                        financeItem
                            ? [i18n.t('restaurant.finance')]
                            : [
                                  !!params.id
                                      ? i18n.t('restaurant.restaurantDetails')
                                      : i18n.t('restaurant.newRestaurant').toUpperCase(),
                                  ...getRestaurantDetailsTabs(),
                              ]
                    }
                    toUpdate={!!params.id}
                    onAddClick={() => handleSubmit()}
                    onBackClick={() => {
                        if (financeItem) {
                            return dispatch(financeStore.actionCreators.selectFinanceItem(null));
                        }
                        history.push('/restaurants');
                    }}
                    activeTab={activeTab}
                    onTabChange={setActiveTab}
                    formChanged={formChanged}
                />
                {renderTab()}
            </div>
            <Dialog open={showAddContactModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {i18n.t('user.createNewUser')}
                    <div className={classes.dialogTitle}></div>
                </DialogTitle>
                <DialogContent>
                    <AddRestaurantUserForm
                        createUser={createContactPerson}
                        restaurants={[
                            {
                                id: params.id,
                                name: formValues.name,
                                addressLine: formValues.addressLine,
                                zip: formValues.zip,
                                city: formValues.city,
                                logoUrl: formValues.logoUrl ?? '',
                            },
                        ]}
                        currentRestaurant={{
                            id: params.id,
                            name: formValues.name,
                            addressLine: formValues.addressLine,
                            zip: formValues.zip,
                            city: formValues.city,
                            logoUrl: formValues.logoUrl ?? '',
                        }}
                        cancel={() => setShowAddContactModal(false)}
                        isContactPerson={true}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={showUsersTable} maxWidth={'lg'} className={classes.usersDialog}>
                <UsersTable
                    close={() => setShowUsersTable(false)}
                    isContactPersons={true}
                    restaurant={{
                        id: params.id,
                        name: formValues.name,
                    }}
                />
            </Dialog>
        </>
    );
}

import { AppThunkAction } from 'store';

import agent from 'api/agent';
import { RestaurantData } from '../restaurantsStore/reducer';
import axios from 'axios';
import { history } from 'index';
import globalStore from '../globalStore';
import { UserAction } from './actions';

export const actionCreators = {
    userSetRole:
        (role: string): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_ROLE',
                role: role,
            });
        },
    getUserRestaurants: (): AppThunkAction<UserAction> => (dispatch) => {
        dispatch({
            type: 'USER_GET_RESTAURANTS_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.User.GetUserRestaurants()
            .then((response) =>
                dispatch({
                    type: 'USER_GET_RESTAURANTS_SUCCESS',
                    restaurants: response,
                })
            )
            .catch((e) => {
                dispatch({
                    type: 'USER_GET_RESTAURANTS_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
    setUserCurrentRestaurant:
        (id: string): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_CURRENT_RESTAURANT_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.User.SetUserCurrentRestaurant(id)
                .then((response) => {
                    dispatch({
                        type: 'USER_SET_CURRENT_RESTAURANT_SUCCESS',
                        restaurantId: response,
                    });
                    if (window.location.pathname.includes('menu/')) {
                        history.push('/menu');
                    }
                    window.location.reload();
                })
                .catch((e) => {
                    dispatch({
                        type: 'USER_SET_CURRENT_RESTAURANT_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getBrowserData: (): AppThunkAction<UserAction> => (dispatch) => {
        axios
            .get('https://ipapi.co/json', {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then((res) => {
                dispatch({
                    type: 'GET_BROWSER_DATA_SUCCESS',
                    data: res.data,
                });
            })
            .catch(() => {
                dispatch({
                    type: 'GET_BROWSER_DATA_ERROR',
                });
            });
    },
    getUserProfile: (): AppThunkAction<UserAction> => (dispatch) => {
        dispatch({
            type: 'USER_GET_PROFILE_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.User.GetUserProfile()
            .then((response) =>
                dispatch({
                    type: 'USER_GET_PROFILE_SUCCESS',
                    profile: response,
                })
            )
            .catch((e) => {
                dispatch({
                    type: 'USER_GET_PROFILE_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
    setCurrentPosition:
        (position: string): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_CURRENT_POSITION',
                position: position,
            });
        },
    setCategory:
        (category?: string): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_CATEGORY',
                category: category,
            });
        },
    setUserPositiosnAndCategories:
        (data: RestaurantData[]): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_POSITIONS_AND_CATEGORIES',
                data: data,
            });
        },
};

import React from 'react';
import styles from '../../styles';
import { Input } from 'components/shared';
import * as FormikFields from 'formik-material-ui';
import { Divider } from '@material-ui/core';
import { ServiceName, Values } from '../types';
import {
    getServiceName,
    renderMultipleClassNames,
    getImageByService,
} from 'helpers/helperFunctions';

type ServiceTopViewProps = {
    values: Values;
    onChangeActive: (service: ServiceName, isActive: boolean) => void;
};

export default function ServiceTopView(props: ServiceTopViewProps) {
    const classes = styles();

    function renderCheckBox(name: ServiceName) {
        return (
            <Input
                component={FormikFields.CheckboxWithLabel}
                variant="filled"
                checked={props.values[name].isActive}
                onChange={() => props.onChangeActive(name, !props.values[name].isActive)}
                name={name}
                type="checkbox"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                color="primary"
                style={{ left: 13.5, padding: 0 }}
            />
        );
    }

    const renderServiceBlock = (serviceName: ServiceName, style?: any) => {
        return (
            <div className={renderMultipleClassNames([classes.seviceTopColumnContainer, style])}>
                {renderCheckBox(serviceName)}
                <span className={classes.serviceNameContainer}>{getServiceName(serviceName)}</span>
                {getImageByService(serviceName, classes, props.values[serviceName].isActive)}
                <Divider className={classes.divider70px} />
            </div>
        );
    };

    return (
        <div className={classes.servicesTopContainer}>
            <div className={classes.serviceTopLeft}>
                MO{/* as dayName in ServiceDayRow (hiden here)*/}
            </div>
            {renderServiceBlock('homeDelivery')}
            {renderServiceBlock('takeAway')}
            {renderServiceBlock('dineIn', classes.seviceTopColumnContainerRight)}
        </div>
    );
}

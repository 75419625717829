import { makeStyles, Theme, createStyles, alpha } from '@material-ui/core';
import commonStyles from 'config/commonStyles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        container: {
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            padding: '4px 8px',
            justifyContent: 'space-between',
        },
        columnContainer: {
            marginTop: '3px',
            marginLeft: '5px',
            //width: '100%',
            // height: '75vh',
            margin: '1px',
            borderRadius: '4px',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        hoverStyle: {
            backgroundColor: alpha(theme.palette.common.white, 0.1),
        },
        ordersTable: {
            width: 218,
            overflowY: 'auto',
            overflowX: 'hidden',
            minHeight: '100%',
            display: 'flex',
            marginLeft: -30,
            flexDirection: 'column',
            '& .MuiTypography-root.MuiTypography-h6': {
                fontSize: 16,
                letterSpacing: 0.15,
            },
            '& .MuiToolbar-root.MuiToolbar-regular': {
                padding: '0 16px !important',
                justifyContent: 'space-between',
            },
            '& .MuiTablePagination-selectIcon': {
                color: theme.palette.action.disabled,
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
            },
            '& .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.MuiToolbar-gutters': {
                padding: '16px 20px 16px 0 !important',
                justifyContent: 'space-between',
            },
            '& div.MuiTablePagination-spacer+p': {
                display: 'none',
            },
            '& .MuiTablePagination-spacer': {
                display: 'none',
            },
            '& td': { cursor: 'pointer' },
            '& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before':
                {
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                },
            '& .MuiFormControl-fullWidth': {
                marginTop: -16,
                width: 200,
            },
            '& .MuiInputLabel-shrink': {
                display: 'none',
            },
            '& .MuiTableContainer-root': {
                height: 'inherit',
            },
        },
        ordersTableHeader: {
            color: theme.palette.action.disabled,
            '& .MuiTableSortLabel-icon': {
                marginTop: '0px !important',
            },
            '&:not(:first-child)': {
                paddingLeft: 0,
            },
            '&:last-child': {
                padding: '0 16px 16px 0 !important',
            },
        },
        lensIcon: {
            marginLeft: 20,
            cursor: 'pointer',
        },
        noPadding: {
            paddingLeft: 0,
        },
        activeColor: {
            backgroundColor: alpha(theme.palette.primary.light, 0.16),
        },
        resetSearchIcon: {
            padding: 9,
            color: theme.palette.primary.main,
            cursor: 'pointer',
            width: 20,
        },
        nothingFoundContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            '& h2': {
                color: theme.palette.primary.main,
            },
            '& div': {
                fontWeight: 700,
                fontSize: 14,
            },
        },
        listContainer: {
            overflowY: 'auto',
            overflowX: 'hidden',
            '& .MuiTableCell-body': {
                paddingTop: 12,
                paddingBottom: 12,
            },
        },
        paging: {
            backgroundColor: theme.palette.common.white,
        },
        searchBar: {
            ...commonStyles.flexRowStart,
            margin: '0 !important',
            height: 70,
            minHeight: '70px !important',
        },
        addNewOrderIcon: {
            height: 20,
            width: 20,
            color: theme.palette.primary.main,
        },
        actionsContainer: {
            ...commonStyles.flexRowSpaceBetween,
            '& img': {
                cursor: 'pointer',
            },
            width: 50,
        },
        sectionCardContainer: {
            minHeight: '63px',
            width: '100%',
            backgroundColor: theme.palette.common.white,
            borderRadius: '4px',
            //margin: '4px 16px 0 0',
            margin: '4px 0',
        },
        sectionCardInnerContainer: {
            padding: '14px 16px',
        },
        sectionCardTitle: {
            fontSize: '18px',
            fontWeight: '500',
            maxWidth: '65%',
        },
        sectionCardOrder: {
            fontSize: '18px',
            fontWeight: '500',
        },
        sectionCardIngredients: {
            fontSize: '14px',
            maxWidth: '65%',
        },
        sectionCardAmountContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2px 8px',
            borderRadius: '5px',
            position: 'relative',
            bottom: -6,
            right: -7,
            backgroundImage: 'linear-gradient(#FFD79A, #FF9F9F)',
        },
        sectionCardAmountContainerText: {
            color: theme.palette.primary.dark,
            fontSize: '18px',
            fontWeight: '700',
        },
        sectionCardClientNotesContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '5px',
            borderTop: `1px solid ${theme.palette.secondary.light}`,
            padding: '10px 16px',
        },
        sectionCardClientNotesTitle: {
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '1.5px',
            margin: '5px 0 10px 0',
            fontWeight: '400',
        },
        sectionCardClientNotesText: {
            fontSize: '14px',
            letterSpacing: '0.25px',
            fontWeight: '400',
        },
        qrNameText: {
            fontSize: '14px',
            fontWeight: '700',
            marginTop: '10px',
        },
        passiveContainer: {
            opacity: 0.5,
        },
        sectionHeaderContainer: {
            backgroundColor: theme.palette.common.white,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            //width: '279px',
            marginLeft: '5px',
            padding: 8,
            borderRadius: '4px',
            border: `1px solid ${theme.palette.grey['300']}`,
        },
        filterButton: {
            width: '73px',
            height: '27px',
            borderRadius: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            margin: 2,
        },
        activeFilterButton: {
            backgroundColor: theme.palette.primary.main,
        },
        passiveFilterButton: {
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        activeFilterButtonText: {
            color: theme.palette.common.white,
            fontWeight: '700',
        },
        passiveFilterButtonText: {
            color: theme.palette.primary.main,
            fontWeight: '700',
        },
        sectionHeaderText: {
            fontSize: '16px',
            fontWeight: '400',
        },
        sectionIcon: {
            cursor: 'pointer',
        },
        filterButtonContainer: {
            padding: '20px 0 0 0',
            flexWrap: 'wrap',
        },
        dishSizeCook: {
            background: '#E7E7E7',
            borderRadius: 5,
            fontWeight: 400,
            fontSize: 16,
            textAlign: 'center',
            color: theme.palette.primary.dark,
            paddingTop: 4.5,
            paddingBottom: 4.5,
            paddingRight: 8,
            paddingLeft: 8,
            bottom: -6,
            position: 'relative',
        },
    })
);

import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core';
import commonStyles from 'config/commonStyles';

const styles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        mainContainer: {
            height: '100%',
            maxHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        tab: {
            padding: '0 0 0 3.85%',
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
            '& .MuiTab-wrapper': {
                letterSpacing: 1.25,
            },
        },
        noPadding: {
            padding: '0',
        },
        activeTab: {
            color: theme.palette.primary.main,
        },
        tabButtons: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '& button': {
                margin: '0 5px',
            },
        },
        sectionContainer: {
            height: 'inherit',
            maxHeight: 'calc(100% - 50px)',
            borderRadius: '4px',
        },
        container: {
            height: '100%',
            display: 'flex',
            '& .MuiFilledInput-multiline': {
                height: 135,
            },
            '& .MuiFilledInput-inputMultiline': {
                height: '100%',
            },
        },
        restaurantDetailsLeftContainer: {
            width: '65%',
            padding: '15px 20px',
            borderRight: '1px solid #E5E5E5',
        },
        restaurantDetailsRightContainer: {
            width: '35%',
            padding: '5px 15px',
            overflow: 'auto',
            // maxHeight: '90%',
            backgroundColor: alpha(theme.palette.secondary.light, 0.3),
        },
        flexRowContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: '13.5%',
            marginBottom: '-5%',
        },
        foodLogo: {
            width: '96px',
            height: '96px',
            borderRadius: '48px',
        },
        restaurantImage: {
            width: '348px',
            height: '158px',
            objectFit: 'cover',
        },
        uploadLogoTextContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignSelf: 'center',
            width: '160px',
            marginTop: '10px',
            marginLeft: '-8px',
            zIndex: 1,
        },
        uploadLogoText: {
            fontWeight: '500',
            color: theme.palette.primary.main,
            cursor: 'pointer',
            marginTop: 20,
            fontSize: 14,
        },
        restaurantPhotoContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '48%',
            height: '158px',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.grey['300']}`,
            position: 'relative',
        },
        uploadPhotoContainer: {
            height: 43,
            width: 43,
            backgroundColor: theme.palette.background.default,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 4,
        },
        removePhotoContainer: {
            ...commonStyles.imageContainerCenter,
            backgroundSize: 'cover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            '& > div': {
                height: 43,
                width: 43,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 4,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                cursor: 'pointer',
            },
        },
        formContainer: {
            width: '100%',
        },
        formInput: {
            marginBottom: '4%',
            width: '48%',
        },
        halfFormInputContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '48%',
            marginBottom: '4%',
        },
        halfFormInput: {
            width: '48%',
        },
        serviceSectionContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '4%',
        },
        basicServiceSectionContainer: {
            display: 'flex',
            alignItems: 'center',
            marginTop: '4%',
        },
        serviceSectionTitle: {
            width: '10%',
        },
        divider: {
            width: '150px',
            marginRight: '20px',
        },
        divider70px: {
            width: '70px',
        },
        halfDivider: {
            width: '55%',
            marginRight: '1%',
        },
        homeDeliveryIcon: {
            width: '35px',
        },
        takeAwayIcon: {
            width: '29px',
            marginLeft: '2px',
        },
        takeOutIcon: {
            width: '32px',
        },
        spinner: {
            marginLeft: '50%',
            marginTop: '28%',
        },
        categoriesContainer: {
            padding: '15px 0',
            //height: '680px',
            maxHeight: 'calc(100% - 50px)',
            overflow: 'auto',
            '& .MuiTableCell-paddingNone div': {
                width: '100%',
            },
            '& table thead tr th, & table tbody tr:first-child td': {
                border: 'none',
            },
        },
        tableHead: {
            color: theme.palette.secondary.main,
            fontSize: '14px',
            fontWeight: '500',
        },
        test: {
            width: '100%',
            backgroundColor: theme.palette.secondary.main,
        },
        hourText: {
            fontSize: '14px',
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        workingHoursContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginRight: '3%',
            width: '30%',
        },
        dayContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '12%',
            height: '35px',
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '3px',
            '&:hover': {
                color: theme.palette.common.white,
                cursor: 'pointer',
                backgroundColor: theme.palette.primary.main,
            },
        },
        selectedDayContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '12%',
            height: '35px',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '3px',
            '&:hover': {
                color: theme.palette.common.white,
                cursor: 'pointer',
            },
        },
        checkboxContainer: {
            display: 'flex',
            alignSelf: 'flex-start',
            alignItems: 'center',
        },
        root: {
            '& .MuiTableCell-head': {
                color: 'rgba(158, 158, 158, 1)',
                paddingTop: 16,
            },
            '& .MuiListItem-gutters': {
                padding: '0',
            },
            '& .MuiPaper-root': {
                width: 1115,
            },
            '& .MuiToolbar-root': {
                paddingRight: 30,
                paddingLeft: 16,
                minHeight: 40,
                fontSize: 16,
            },
            '& .MuiTypography-root': {
                fontSize: 16,
            },
            '& .MuiCheckbox-root': {
                padding: 0,
            },
            //height: 725,
        },
        contactsTable: {
            minWidth: 650,
            '& th:first-child': {
                width: 10,
            },
            '& tr td:last-child': {
                padding: '0 15px 0 0',
            },
            '& .MuiTableCell-paddingNone': {
                padding: '0 !important',
            },
            overflow: 'auto',
        },
        dots: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        toolbarTitle: {
            flex: '1 1 100%',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
            '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
            },
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
        dialog: {
            '& .MuiDialogTitle-root > h2': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        tableHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 16,
        },
        makePhotoMainCheckbox: {
            width: '48%',
        },
        currencyText: {
            color: theme.palette.primary.main,
            fontWeight: '400',
            fontSize: '14px',
            marginTop: '6px',
            marginRight: '2px',
        },
        currencyAmount: {
            color: theme.palette.primary.main,
            fontWeight: '700',
            fontSize: '24px',
        },
        bottomLine: {
            padding: '3px 0',
            borderBottom: `1px solid ${theme.palette.grey['300']}`,
        },
        listBox: {
            marginTop: 0,
            padding: 0,
        },
        paper: {
            marginTop: 0,
        },
        dropdownItem: {
            padding: 0,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: alpha(theme.palette.primary.main, 0.16),
            },
        },
        selectedDropdownItem: {
            backgroundColor: alpha(theme.palette.primary.main, 0.16),
        },
        closedText: {
            color: alpha(theme.palette.common.black, 0.6),
            marginBottom: '2px',
            fontSize: '14px',
        },
        cancelButton: {
            marginRight: '15px!important',
        },
        serviceText: {
            width: '160px',
        },
        financeTabText: {
            fontSize: '20px',
            fontWeight: '500',
            color: theme.palette.common.black,
        },
        saveChangesButton: {
            height: '75%',
            width: 171,
            position: 'absolute',
            right: 10,
            top: 6,
        },
        dishesSelect: {
            '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled': {
                margin: 0,
            },
        },
        coffeeCupIcon: {
            marginRight: 10,
            height: 20,
            width: 20,
            borderRadius: 50,
            backgroundColor: theme.palette.common.white,
            backgroundPosition: 'center',
            backgroundSize: 'auto',
            backgroundRepeat: 'no-repeat',
        },
        threeDotsMenuDisabledCell: {
            borderBottomColor: 'rgba(224, 224, 224, 0.35)',
        },
        disabledRow: {
            opacity: 0.3,
        },
        servicesContainer: {
            borderWidth: 1,
            borderColor: '#99567E',
        },
        servicesDayRowsContainer: {
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        servicesTopContainer: {
            flexDirection: 'row',
            display: 'flex',
        },
        serviceTopLeft: {
            display: 'flex',
            width: 65,
            backgroundColor: '#fff',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            color: '#0000',
            marginRight: 8,
        },
        seviceTopColumnContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: 96,
            width: 168,
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid #99567E',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            borderBottomWidth: 0,
            backgroundColor: '#fff',
            marginRight: 8,
        },
        seviceTopColumnContainerRight: {
            marginRight: 0,
        },
        serviceDayRowContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        serviceRowDayName: {
            display: 'flex',
            width: 65,
            backgroundColor: '#fff',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 8,
        },
        hoursContainer: {
            marginTop: 8,
            marginBottom: 8,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
        },
        addHoursButtons: {
            marginTop: 16,
            marginBottom: 16,
        },
        serviceNameContainer: {
            fontSize: 10,
            textAlign: 'center',
        },
    })
);

export default styles;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Paper,
    TablePagination,
} from '@material-ui/core';
import i18n from 'i18n-js';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { pagingOptions } from 'constants/arrays';
import { useStyles } from './styles';
import globalStore from 'store/globalStore';
import agent from 'api/agent';
import { useAppSelector } from 'index';
import { formatDateIntl } from 'helpers/datetimeHelper';

interface ClosingReport {
    id: string;
    dateFrom: number;
    dateTo: number;
    totalAmount: number;
    totalTax: number;
}

export default function Closings() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const language = useAppSelector((state) => state.global.language);
    const [count, setCount] = useState(0);
    const [reports, setReports] = useState<ClosingReport[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pagingOptions[page]);

    async function createNewReport() {
        dispatch(globalStore.actionCreators.showSpiner());
        try {
            const newReport = await agent.Payments.CreateNewClosingReport();
            if (newReport) {
                await agent.Payments.GenerateClosinfReportPDF(newReport.id);
                setReports([newReport, ...reports]);
            }
        } catch (error) {
            dispatch(globalStore.actionCreators.showToaster('error', error));
        } finally {
            dispatch(globalStore.actionCreators.hideSpiner());
        }
    }

    async function downloadReportPDF(id: string) {
        dispatch(globalStore.actionCreators.showSpiner());
        try {
            await agent.Payments.GenerateClosinfReportPDF(id);
        } catch (error) {
            dispatch(globalStore.actionCreators.showToaster('error', error));
        } finally {
            dispatch(globalStore.actionCreators.hideSpiner());
        }
    }

    useEffect(() => {
        const getReports = async () => {
            dispatch(globalStore.actionCreators.showSpiner());
            try {
                const closings = await agent.Payments.GetClosingsReports(page, rowsPerPage);
                if (closings) {
                    setReports(closings.list);
                    setCount(closings.count);
                }
            } catch (error) {
                dispatch(globalStore.actionCreators.showToaster('error', error));
            } finally {
                dispatch(globalStore.actionCreators.hideSpiner());
            }
        };
        getReports();
    }, [page, rowsPerPage]);
    return (
        <div className={classes.root}>
            <Toolbar
                style={{
                    paddingBottom: '10px',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className={classes.iconButton}
                        onClick={() => history.goBack()}
                    >
                        <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                </div>

                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 5, width: '212px' }}
                    onClick={createNewReport}
                >
                    {i18n.t('common.generateReport').toUpperCase()}
                </Button>
            </Toolbar>
            <Paper>
                <TableContainer id="container" style={{ maxHeight: '90%' }}>
                    <Table stickyHeader className={classes.root} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={classes.tableCellHeader}
                                    width={'20%'}
                                    style={{ paddingLeft: 22 }}
                                >
                                    <div className={classes.tableCellHeaderContainer}>
                                        {i18n.t('common.start')}
                                    </div>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} width={'20%'}>
                                    <div className={classes.tableCellHeaderContainer}>
                                        {i18n.t('common.end')}
                                    </div>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} width={'18%'}>
                                    <div className={classes.tableCellHeaderContainer}>
                                        {i18n.t('common.total')}
                                    </div>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} width={'18%'}>
                                    <div className={classes.tableCellHeaderContainer}>
                                        {i18n.t('paymentDetails.vat')}
                                    </div>
                                </TableCell>
                                <TableCell className={classes.tableCellHeader} width={'18%'}>
                                    <div
                                        className={classes.tableCellHeaderContainer}
                                        style={{ padding: 16 }}
                                    >
                                        {i18n.t('common.actions')}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reports.map((report, i) => (
                                <TableRow key={i}>
                                    <TableCell>
                                        <span>{formatDateIntl(report.dateFrom, language)}</span>
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        <span>{formatDateIntl(report.dateTo, language)}</span>
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        <span>{report.totalAmount}</span>
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        <span>{report.totalTax}</span>
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            className={classes.iconButton}
                                            style={{ marginLeft: 10 }}
                                            onClick={() => downloadReportPDF(report.id)}
                                        >
                                            <PictureAsPdfIcon fontSize="medium" color="primary" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={pagingOptions}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={i18n.t('common.rowsPerPage')}
                    page={page}
                    onPageChange={(_, page) => setPage(page)}
                    onRowsPerPageChange={(event) => setRowsPerPage(+event.target.value)}
                />
            </Paper>
        </div>
    );
}

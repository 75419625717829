import React, { useEffect } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { isTablet, isMobile } from 'react-device-detect';
import { TouchBackend } from 'react-dnd-touch-backend';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Routes from './routes/Routes';
import { history, ApplicationState } from './store';
import Layout from './components/main/Layout';
import { Spinner, ToastrNotification } from './components/shared';
import { localizationConfig } from './config/localizationConfig';
import userManager from 'managers/userManager';
import { default as userStore } from 'store/userStore';
import './App.css';

function App() {
    localizationConfig();
    const hist = useHistory();
    const dispatch = useDispatch();
    const browserData = useSelector((state: ApplicationState) => state.user.browserData);
    const excludedURLS = ['privacy-policy', 'terms-conditions', 'disclaimer'];
    const language = useSelector((state: ApplicationState) => state.global.language);
    userManager.getUser().then((user) => {
        if (!user && !excludedURLS.includes(hist?.location?.pathname)) {
            hist?.push('/redirecttologin');
        } else {
            dispatch(userStore.actionCreators.userSetRole(user?.profile.role));
            !browserData && dispatch(userStore.actionCreators.getBrowserData());
        }
    });

    const options = {
        scrollAngleRanges: [
            { start: 30, end: 150 },
            { start: 210, end: 330 },
        ],
        enableMouseEvents: true,
    };

    useEffect(() => {}, [language]);
    return (
        <DndProvider backend={isMobile || isTablet ? TouchBackend : HTML5Backend} options={options}>
            <ConnectedRouter history={history}>
                <Layout>
                    <ToastrNotification />
                    <Spinner />
                    <Routes />
                </Layout>
            </ConnectedRouter>
        </DndProvider>
    );
}

export default App;

export enum Roles {
    ADMIN = 'Admin',
    OWNER = 'RestaurantOwner',
    USER = 'MobileUser',
    EMPLOYEE = 'RestaurantEmployee',
}

export enum MenuType {
    Daily,
    Standard,
    Special,
}

export enum OrderStatus {
    Cart,
    Order,
    InProgress,
    Ready,
    Delivery,
    Closed,
    Cancelled,
}

export enum OrderItemStatus {
    Cart,
    Order,
    InProgress,
    Ready,
    Closed,
    Cancelled,
}

export enum Positions {
    WAITER = 'Waiter',
    BARMAN = 'Barman',
    COOK = 'Cook',
    MANAGER = 'Manager',
}

export enum PaymentMethod {
    None,
    Online,
    Card,
    Cash,
    PayLater,
}

export enum OrderUpdateStatus {
    UpdateReadyDishes,
    UpdateOrdersCount,
    ShowOverduedOrders,
}

export enum OrderServices {
    HomeDelivery = 'HomeDelivery',
    DineIn = 'DineIn',
    TakeAway = 'TakeAway',
}

export enum TaxType {
    None = 'None',
    StandardRate = 'StandardRate',
    ReducedRate = 'ReducedRate',
    SpecialRateForAccommodation = 'SpecialRateForAccommodation',
}

export enum SpicinessLevels {
    None,
    MildlySpicy,
    MediumSpicy,
    FieryHot,
}

export enum Diets {
    None,
    Vegetarian,
    Vegan,
}

export enum DietaryLaws {
    None,
    Halal,
    Kosher,
}

export enum PaymentStatus {
    Open,
    Success,
    Failed,
    Cancelled,
}

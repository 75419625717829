export const pagingOptions = [13, 25, 50, 100];

export const statusColor = [
    'transparent', //cart
    '#5FCF55', //new - order
    '#E1BA2E', //in progress
    '#0073DE', // ready
    '#5FCF55', //on delivery
    '#B0B0B0', // closed
    '#592A46', //cancel
    '#D55336', // unpaid
    '#B0B0B0', // Non
    '#E1BA2E', //delivered
];

export const statusText = ['Cart', 'New', 'InProgress', 'Ready', 'Delivery', 'Closed', 'Cancelled'];

import React, { useState, useEffect } from 'react';

import i18n from 'i18n-js';
import { InputAdornment, TextField as MuiTextField } from '@material-ui/core';
import { Typography, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { images } from 'assets';
import { TimePickerModal } from 'components/shared';
import { getInitialDates } from '../functions';
import styles from '../styles';
import { DayKey, HourData, ServiceName } from './types';
import {
    getImageByService,
    getServiceName,
    makeUniqueHours,
    renderMultipleClassNames,
} from 'helpers/helperFunctions';

export interface AddWorkingHoursProps {
    isVisible: boolean;
    onClose: () => void;
    onSave: (dayHours: HourData[]) => void;
    service: ServiceName | undefined;
    daykey: DayKey | undefined;
    initHours: HourData[];
}

export const defaultHours = { from: '00.00', to: '00.00' };
const initStateHours = [defaultHours];

export default function AddWorkingHoursModalNew(props: AddWorkingHoursProps) {
    const [render, reRender] = useState(false);
    const [timePickerModalType, setTimePickerModalType] = useState(0);
    const [clickedElementRect, setClickedElementRect] = useState<any>(null);
    const [hours, setHours] = useState<HourData[]>(initStateHours);
    const classes = styles();

    useEffect(() => {
        if (
            props.initHours?.length !== 0 &&
            JSON.stringify(props.initHours) !== JSON.stringify(hours)
        ) {
            setHours(props.initHours);
        }
    }, [props.initHours]);

    function addMoreHours() {
        const nextIndex = hours.length;
        addWorkingHour(nextIndex, defaultHours);
    }

    function getClickedAndSet(i: number) {
        const id = `Add-Mui${i}`;
        const clickedElement = document.getElementById(id);
        const clickedElementRect = clickedElement?.getBoundingClientRect();

        setClickedElementRect(clickedElementRect);
    }

    function addWorkingHour(i: number, incomingHours: HourData) {
        const newHours = [...hours];
        newHours[i] = incomingHours;
        setHours(newHours);
    }

    function removeWorkingHour(i: number) {
        const newHours = hours.filter((item, index) => index !== i);
        setHours(newHours);
    }

    function onSave() {
        const updatedHours = makeUniqueHours(hours);
        if (JSON.stringify(props.initHours) === JSON.stringify(updatedHours)) {
            props.onClose();
            return;
        }
        props.onSave(makeUniqueHours(updatedHours));
        setHours(initStateHours);
    }

    function getDayName(daykey: DayKey) {
        switch (daykey) {
            case 'monday':
                return i18n.t('common.monday');
            case 'tuesday':
                return i18n.t('common.tuesday');
            case 'wednesday':
                return i18n.t('common.wednesday');
            case 'thursday':
                return i18n.t('common.thursday');
            case 'friday':
                return i18n.t('common.friday');
            case 'saturday':
                return i18n.t('common.saturday');
            case 'sunday':
                return i18n.t('common.sunday');
            default:
                return '';
        }
    }

    function renderTitle() {
        return `${getServiceName(props.service)} / ${getDayName(props.daykey as DayKey)}`;
    }

    return (
        <Dialog open={props.isVisible} aria-labelledby="form-dialog-title">
            <DialogTitle
                id="form-dialog-title"
                style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            >
                <div style={{ marginRight: 8 }}>{getImageByService(props.service, classes)}</div>
                <Typography variant="h6" align="left" className={classes.fontWeight500}>
                    {renderTitle()}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div>
                    {hours.map((workingHour: HourData, i: number) => (
                        <div key={i}>
                            <MuiTextField
                                className={classes.width100}
                                id={`Add-Mui${i}`}
                                value={workingHour ? workingHour.from + ' - ' + workingHour.to : ''}
                                onClick={() => {
                                    setTimePickerModalType(i + 1);
                                    getClickedAndSet(i);
                                }}
                                variant="filled"
                                name={`extraHours[${i}]`}
                                type="text"
                                label={i18n.t('common.workingHours') + ` ${i > 0 ? i + 1 : ''}`}
                                placeholder={i18n.t('common.workingHours')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                removeWorkingHour(i);
                                                reRender(!render);
                                            }}
                                        >
                                            <img
                                                alt="remove-icon"
                                                src={images.icons.removeCircle}
                                                className={classes.basicHover}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    ))}
                    <div
                        className={renderMultipleClassNames([
                            classes.flexRowSpaceBetween,
                            classes.addHoursButtons,
                        ])}
                    >
                        <Button
                            className={classes.mTop10}
                            type="button"
                            color="primary"
                            onClick={addMoreHours}
                        >
                            {i18n.t('common.addMoreHours')}
                        </Button>
                        <div className={classes.smallModalButtonContainer}>
                            <Button
                                type="button"
                                color="primary"
                                className={classes.cancelButton}
                                onClick={() => {
                                    props.onClose();
                                    setHours(initStateHours);
                                }}
                            >
                                {i18n.t('button.cancel')}
                            </Button>
                            <Button
                                disabled={JSON.stringify(props.initHours) === JSON.stringify(hours)}
                                onClick={onSave}
                                className={classes.smallButton}
                                variant="contained"
                                color="primary"
                            >
                                {i18n.t(`common.save`)}
                            </Button>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <TimePickerModal
                initialFromHour={getInitialDates(hours[timePickerModalType - 1], 'from', 'hour')}
                initialFromMinute={getInitialDates(
                    hours[timePickerModalType - 1],
                    'from',
                    'minute'
                )}
                initialToHour={getInitialDates(hours[timePickerModalType - 1], 'to', 'hour')}
                initialToMinute={getInitialDates(hours[timePickerModalType - 1], 'to', 'minute')}
                clickedElementRect={clickedElementRect}
                isVisible={timePickerModalType > 0}
                onClose={(selectedHours) => {
                    addWorkingHour(timePickerModalType - 1, selectedHours);
                    setTimePickerModalType(0);
                }}
                onDateChange={(selectedHours) => {
                    addWorkingHour(timePickerModalType - 1, selectedHours);
                }}
            />
        </Dialog>
    );
}

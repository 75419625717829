import React, { useState } from 'react';
import styles from '../../styles';
import { images } from 'assets';
import { Values, DayKey, HourData, ServiceName, ServiceValue } from '../types';
import { getDayName } from 'helpers/helperFunctions';

type DayRowProps = {
    name: DayKey;
    isSunday?: boolean;
    values: Values;
    onClick: (params: ServiceName) => void;
    homeDelivery: ServiceValue;
    takeAway: ServiceValue;
    dineIn: ServiceValue;
};

export default function ServiceDayRow({
    homeDelivery,
    takeAway,
    dineIn,
    name,
    isSunday,
    onClick,
}: DayRowProps) {
    const classes = styles();
    const [isHomeHovered, setIsHomeHovered] = useState(false);
    const [isTakeHovered, setIsTakeHovered] = useState(false);
    const [isSiteHovered, setIsSiteHovered] = useState(false);

    function renderPlus(service: ServiceName) {
        return <img alt="plus-mob" src={images.icons.plusTime} onClick={() => onClick(service)} />;
    }

    function getHours(
        service: ServiceName,
        serviceValue: ServiceValue,
        isHovered: boolean,
        setHovered: (is: boolean) => void
    ) {
        const hoursByDay = serviceValue.workingHours[name].length
            ? serviceValue.workingHours[name]
            : [];
        if (hoursByDay.every((i) => i.from === '00.00' && i.to === '00.00'))
            return renderPlus(service);

        return hoursByDay.map(({ from, to }: HourData) => {
            return (
                <div
                    onClick={() => onClick(service)}
                    onMouseEnter={() => {
                        setHovered(true);
                    }}
                    onMouseLeave={() => {
                        setHovered(false);
                    }}
                    style={{
                        color: serviceValue.isActive
                            ? isHovered
                                ? '#592A46'
                                : '#99567E'
                            : '#5B5858',
                        textDecoration: isHovered ? 'underline' : 'none',
                        fontWeight: 500,
                    }}
                >
                    {from} - {to}
                </div>
            );
        });
    }

    return (
        <div className={classes.serviceDayRowContainer}>
            <div className={classes.serviceRowDayName}>
                <span>{getDayName(name).substring(0, 2).toUpperCase()}</span>
            </div>
            <div style={getColumnStyle({ isSunday })}>
                <div className={classes.hoursContainer}>
                    {getHours('homeDelivery', homeDelivery, isHomeHovered, setIsHomeHovered)}
                </div>
            </div>
            <div style={getColumnStyle({ isSunday })}>
                <div className={classes.hoursContainer}>
                    {getHours('takeAway', takeAway, isTakeHovered, setIsTakeHovered)}
                </div>
            </div>
            <div style={getColumnStyle({ isSunday, isLast: true })}>
                <div className={classes.hoursContainer}>
                    {getHours('dineIn', dineIn, isSiteHovered, setIsSiteHovered)}
                </div>
            </div>
        </div>
    );
}

function getColumnStyle({ isSunday, isLast }: { isSunday?: boolean; isLast?: boolean }) {
    return {
        flexDirection: 'column',
        display: 'flex',
        width: 168,
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #99567E',
        borderBottomLeftRadius: isSunday ? 4 : 0,
        borderBottomRightRadius: isSunday ? 4 : 0,
        borderTopWidth: 0,
        borderBottomWidth: isSunday ? 1 : 0,
        backgroundColor: '#fff',
        marginRight: isLast ? 0 : 8,
    } as React.CSSProperties;
}

import React from 'react';
import styles from '../../styles';
import { Values, DayKey, ServiceName } from '../types';
import ServiceTopView from './ServiceTopView';
import ServiceDayRow from './ServiceDayRow';
import { replaceColonWithDotServicesHours } from 'helpers/helperFunctions';

type Props = {
    values: Values;
    onClick: ({ dayName, service }: { dayName: DayKey; service: ServiceName }) => void;
    onChangeActive: (service: ServiceName, isActive: boolean) => void;
};

const DAYS = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
] as DayKey[];

export default function Services(props: Props) {
    const classes = styles();
    replaceColonWithDotServicesHours(props.values);
    if (!props.values.homeDelivery && !props.values.takeAway && props.values.dineIn) return <div />;

    function renderDayRow(dayName: DayKey) {
        return (
            <ServiceDayRow
                name={dayName}
                values={props.values}
                homeDelivery={props.values.homeDelivery}
                takeAway={props.values.takeAway}
                dineIn={props.values.dineIn}
                onClick={(service: ServiceName) => props.onClick({ dayName, service })}
                isSunday={dayName === 'sunday'}
            />
        );
    }
    return (
        <div>
            <ServiceTopView values={props.values} onChangeActive={props.onChangeActive} />
            <div className={classes.servicesDayRowsContainer}>
                {DAYS.map((dayName) => renderDayRow(dayName))}
            </div>
        </div>
    );
}

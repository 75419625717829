import React, { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { ListItemIcon } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { RemoveCircleOutline } from '@material-ui/icons';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import i18n from 'i18n-js';

import { default as ordersStore } from 'store/ordersStore';
import { ApplicationState } from 'store';
import SectionHeader from './components/SectionHeader';
import SectionCard from './components/SectionCard';
import { useStyles } from './styles';
import OrdersListTable from 'components/orders/ordersList/OrdersListTable';
import { OrderItem } from 'store/ordersStore/reducer';
import { OrderItemStatus } from 'constants/enums';

const columnsWidth = 33;

export default function CookView() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const positionOrdersRedux = useSelector(
        (state: ApplicationState) => state.orders.positionOrders
    );

    const [hideDone, setHideDone] = useState(false);
    const [filters, setFilters] = useState([1, 1, 1]);
    const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
    const [, reRender] = useState(false);
    const positionOrders = useRef<OrderItem[]>([]);

    useEffect(() => {
        positionOrders.current = getUniquePositionOrders();
        reRender((prev) => !prev);
    }, [positionOrdersRedux]);

    const [{ isOver1 }, drop1] = useDrop(() => ({
        accept: 'a',
        canDrop: () => true,
        collect: (monitor) => ({
            isOver1: monitor.isOver(),
        }),
        drop: (item: OrderItem) => {
            changeOrderStatus(item, OrderItemStatus.Order);
        },
    }));
    const [{ isOver2 }, drop2] = useDrop(() => ({
        accept: 'a',
        canDrop: () => true,
        collect: (monitor) => ({
            isOver2: monitor.isOver(),
        }),
        drop: (item: OrderItem) => {
            changeOrderStatus(item, OrderItemStatus.InProgress);
        },
    }));
    const [{ isOver3 }, drop3] = useDrop(() => ({
        accept: 'a',
        canDrop: () => true,
        collect: (monitor) => ({
            isOver3: monitor.isOver(),
        }),
        drop: (item: OrderItem) => {
            changeOrderStatus(item, OrderItemStatus.Ready);
        },
    }));

    useEffect(() => {
        checkHide();
    }, []);

    function getUniquePositionOrders() {
        const newList: OrderItem[] = [];
        positionOrdersRedux.forEach((each) => {
            const hasSame = positionOrders.current.find((old) => old.uniqueId === each.uniqueId);
            newList.push({
                ...each,
                uniqueId: hasSame
                    ? hasSame.uniqueId
                    : (Math.random() + 1).toString(36).substring(7),
            });
        });
        return newList;
    }

    function checkHide() {
        const isHidden = localStorage.getItem('@hideDone');
        if (isHidden !== null) {
            setHideDone(isHidden === 'true');
        }
    }

    function getOrdersList(status: number): OrderItem[] {
        let index = 0;
        switch (status) {
            case OrderItemStatus.Order:
                index = 0;
                break;
            case OrderItemStatus.InProgress:
                index = 1;
                break;
            case OrderItemStatus.Ready:
                index = 2;
                break;
            default:
                break;
        }
        const filteredAndSorted = positionOrders.current
            .filter((each: OrderItem) => selectedOrders.includes(each.orderId!))
            .filter((each: OrderItem) => each.status === status)
            .sort((a, b) => (a.name > b.name ? 1 : -1));
        const filterType = filters[index];
        if (filterType === 0) {
            return filteredAndSorted.sort((a, b) => (a.orderNumber! > b.orderNumber! ? -1 : 1));
        }
        if (filterType === 1) {
            return filteredAndSorted.sort((a, b) => (a.name > b.name ? -1 : 1));
        }
        if (filterType === 2) {
            return filteredAndSorted.sort((a, b) => (a.qrCodeName! > b.qrCodeName! ? -1 : 1));
        }

        return filteredAndSorted;
    }

    function getEachList(status: OrderItemStatus, passive = false) {
        let index = 0;
        switch (status) {
            case OrderItemStatus.Order:
                index = 0;
                break;
            case OrderItemStatus.InProgress:
                index = 1;
                break;
            case OrderItemStatus.Ready:
                index = 2;
                break;
            default:
                break;
        }
        const list = getOrdersList(status);
        return list.map((eachOrder, i) => {
            let isDifferent = false;
            const prevOrder = list[i - 1];
            const filterType = filters[index];
            if (prevOrder) {
                if (filterType == 1) {
                    isDifferent = prevOrder.orderNumber !== eachOrder.orderNumber;
                }
                if (filterType == 2) {
                    isDifferent = prevOrder.name !== eachOrder.name;
                }
                if (filterType == 3) {
                    isDifferent = prevOrder.qrCodeName !== eachOrder.qrCodeName;
                }
            }
            return (
                <div style={{ marginTop: isDifferent ? 16 : 0 }} key={i}>
                    <SectionCard
                        order={eachOrder}
                        key={`${eachOrder.uniqueId}`}
                        passive={passive}
                    />
                </div>
            );
        });
    }

    /**
     * Function will change the status of the order visually after DnD Drop
     * and will update it with API accordingly
     * @param item
     * @param row
     */
    const changeOrderStatus = (item: OrderItem, row: OrderItemStatus) => {
        const status = row;
        let indexOfItem = positionOrders.current.findIndex(
            (each: OrderItem) => each.uniqueId === item.uniqueId
        );
        // IF ORDER MOVED BACK
        if (status < item.status) {
            return;
        }
        if (indexOfItem > -1 && item.status !== status) {
            const newPositionOrders: any = [...positionOrders.current].filter(
                (each) => each.uniqueId !== item.uniqueId
            );
            item.status = status;
            newPositionOrders.push(item);
            dispatch({ type: 'GET_POSITION_ORDERS_SUCCESS', data: newPositionOrders });
            dispatch(
                ordersStore.actionCreators.changePositionOrderStatus({
                    orderId: item.orderId ? item.orderId : '',
                    itemId: item.id,
                    status,
                    previousStatus: item.status - 1,
                    dailyMenuId: item.dailyMenuId,
                })
            );
            reRender((prev) => !prev);
        }
    }; // eslint-disable-line

    function filterClick(i: number, val: number) {
        filters[i] = val;
        setFilters([...filters]);
    }

    function doneThreeDotItems() {
        return [
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => {
                    setHideDone(!hideDone);
                    localStorage.setItem('@hideDone', JSON.stringify(!hideDone));
                }}
            >
                <ListItemIcon>
                    {hideDone ? (
                        <SettingsBackupRestoreIcon fontSize="small" color="disabled" />
                    ) : (
                        <RemoveCircleOutline fontSize="small" color="disabled" />
                    )}
                </ListItemIcon>
                <Typography variant="inherit">
                    {hideDone ? i18n.t('orders.showAll') : i18n.t('orders.clear')}
                </Typography>
            </div>,
        ];
    }

    function getColumnHeight(id: string) {
        const headerHeight = document.getElementById(id)?.style.height;
        const marginTop = '5px';

        //return `calc(90vh - ${headerHeight} - ${marginTop})`;
        return 'calc(100% - 113px)';
    }

    return (
        <div className={classes.container}>
            <div>
                <OrdersListTable
                    onOrderSelected={(orders) => {
                        setSelectedOrders(orders);
                        setTimeout(() => reRender((prev) => !prev), 0);
                    }}
                    selectedOrders={selectedOrders}
                    timeOnly
                />
                {/* Will be removed after testing */}
                {/* <OrdersList
                    onOrderSelected={(orders) => {
                        setSelectedOrders(orders);
                        setTimeout(() => reRender((prev) => !prev), 0);
                    }}
                /> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1 }}>
                <div
                    style={{ width: `${columnsWidth}%`, display: 'flex', flexDirection: 'column' }}
                >
                    <SectionHeader
                        title={i18n.t('orders.toDo')}
                        onFilterClick={(val) => filterClick(0, val)}
                        amount={getOrdersList(OrderItemStatus.Order).length}
                        filter={filters[0]}
                        containerId={'h1'}
                    />
                    <div
                        ref={drop1}
                        className={`${classes.columnContainer} ${isOver1 && classes.hoverStyle}`}
                        style={{ height: getColumnHeight('h1') }}
                    >
                        {getEachList(OrderItemStatus.Order)}
                    </div>
                </div>
                <div
                    style={{ width: `${columnsWidth}%`, display: 'flex', flexDirection: 'column' }}
                >
                    <SectionHeader
                        title={i18n.t('orders.inProgress')}
                        onFilterClick={(val) => filterClick(1, val)}
                        amount={getOrdersList(OrderItemStatus.InProgress).length}
                        filter={filters[1]}
                        containerId={'h2'}
                    />
                    <div
                        ref={drop2}
                        className={`${classes.columnContainer} ${isOver2 && classes.hoverStyle}`}
                        style={{ height: getColumnHeight('h2') }}
                    >
                        {getEachList(OrderItemStatus.InProgress)}
                    </div>
                </div>
                <div
                    style={{ width: `${columnsWidth}%`, display: 'flex', flexDirection: 'column' }}
                >
                    <SectionHeader
                        title={i18n.t('orders.done')}
                        onFilterClick={(val) => filterClick(2, val)}
                        amount={getOrdersList(OrderItemStatus.Ready).length}
                        filter={filters[2]}
                        threeDotItems={doneThreeDotItems()}
                        containerId={'h3'}
                    />
                    <div
                        ref={drop3}
                        className={`${classes.columnContainer} ${isOver3 && classes.hoverStyle}`}
                        style={{ height: getColumnHeight('h3') }}
                    >
                        {!hideDone && getEachList(OrderItemStatus.Ready, true)}
                        {!hideDone && getEachList(OrderItemStatus.Closed, true)}
                    </div>
                </div>
            </div>
        </div>
    );
}
